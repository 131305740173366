// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// The first name of a user.
// Example: "John"

export const firstNameMinLength = 1;
export const firstNameMaxLength = 255;

export const FirstNameSchema = t.refinement(t.string, n => n.length >= firstNameMinLength && n.length <= firstNameMaxLength, 'FirstName');

export type FirstName = string;
