// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LegacyAnimalInPlan, LegacyAnimalInPlanSchema } from 'api/gen/LegacyAnimalInPlan';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LegacyStable, LegacyStableSchema } from 'api/gen/LegacyStable';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  animals: t.array(LegacyAnimalInPlanSchema),
  animalsCount: PositiveIntegerSchema,
  id: PositiveLongSchema,
  importedDate: DateTimeSchema,
  stables: t.array(LegacyStableSchema),
  stablesCount: PositiveIntegerSchema,
});

export const LegacyPlanSchema = excess(requiredPart);

export interface LegacyPlan extends t.TypeOf<typeof LegacyPlanSchema> {}
