import {putAll, showBeError, takeEveryF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {breederAction, UpdateBreedingArealRatingAction} from '../action';
import {simpleBreederSelector} from '../selector';

function* execute({payload: {breedingId, arealRating}}: UpdateBreedingArealRatingAction): SagaIterator {
    const response = yield* call(Api.updateBreeding, {arealRating}, {breedingId});

    if (response.isSuccess) {
        const stable = yield* select(simpleBreederSelector.stable);

        if (stable) {
            yield* put(breederAction.getStableBreedings(stable.id));
        }
    } else {
        yield putAll(showBeError(response, t('breeder')('arealRating')));
    }
}

export function* updateBreederBreedingArealRatingSaga(): SagaIterator {
    yield takeEveryF('breeder/UPDATE_BREEDER_BREEDING_AREAL_RATING', execute);
}
