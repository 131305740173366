import {putAll, showBeError, showSuccess, extractFormErrorsFromResponse, takeLatestF} from 'cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {optEmptyString} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {UpdateBreeder} from 'api/gen/UpdateBreeder';
import {FormStates} from 'app/setup';
import {t} from 'app/translations';
import {formHelpers} from 'utils/forms';

import {UpdateBreederAction} from '../action';

const updateBreederFormName: keyof FormStates = 'breederForm';
const tr = t('breeder');

function* execute({payload: {breederId}}: UpdateBreederAction): SagaIterator {
    yield* put(formHelpers.startSubmit(updateBreederFormName));

    const breederFormValues = (yield* select(formHelpers.formValues('breederForm'))).orCrash('Missing values');
    const values: UpdateBreeder = {
        cin: optEmptyString(breederFormValues.ico).orNull(),
        email: optEmptyString(breederFormValues.email).orNull(),
        phone: optEmptyString(breederFormValues.phone).orNull(),
    };

    const response = yield* call(Api.updateBreeder, values, {breederId});

    if (response.isSuccess) {
        yield* put(showSuccess(tr('farmsAdministration'), tr('changesSaved'), false, 'update-breeder'));

        yield* put(routerActions.push('/breeders'));
    } else {
        yield putAll(showBeError(response, tr('farmAdministration')));
        yield* put(formHelpers.stopSubmit(updateBreederFormName, extractFormErrorsFromResponse(response)));
    }
}

export function* updateBreederSaga(): SagaIterator {
    yield takeLatestF('breeder/UPDATE_BREEDER', execute);
}
