// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractMeasurementDetail, AbstractMeasurementDetailSchema } from 'api/gen/AbstractMeasurementDetail';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { FleckviehMeasurementProperties, FleckviehMeasurementPropertiesSchema } from 'api/gen/FleckviehMeasurementProperties';

const requiredPart = t.interface({
  tag: t.literal('FleckviehMeasurementDetail'),
  properties: FleckviehMeasurementPropertiesSchema,
});

export const FleckviehMeasurementDetailSchema = excess(t.intersection([AbstractMeasurementDetailSchema.type, requiredPart]));

export interface FleckviehMeasurementDetail extends t.TypeOf<typeof FleckviehMeasurementDetailSchema> {}
