// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { KkutFile, KkutFileSchema } from 'api/gen/KkutFile';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { WholeName, WholeNameSchema } from 'api/gen/WholeName';

const requiredPart = t.interface({
  allRecords: PositiveIntegerSchema,
  duplicateRecords: PositiveOrZeroIntegerSchema,
  fileName: KkutFileSchema,
  imported: DateTimeSchema,
  importedBy: WholeNameSchema,
  importedRecords: PositiveOrZeroIntegerSchema,
});

export const ImportSchema = excess(requiredPart);

export interface Import extends t.TypeOf<typeof ImportSchema> {}
