// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// Example: SEND_PLAN

export const EmailTemplateTypeSchema = t.keyof({
  SEND_PLAN: null,
  SEND_BREEDER_SUITE: null,
});

export type EmailTemplateType = t.TypeOf<typeof EmailTemplateTypeSchema>;
