// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalInPlanType, AnimalInPlanTypeSchema } from 'api/gen/AnimalInPlanType';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTagWithoutCountry, EarTagWithoutCountrySchema } from 'api/gen/EarTagWithoutCountry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroLong, PositiveOrZeroLongSchema } from 'api/gen/PositiveOrZeroLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserCode, UserCodeSchema } from 'api/gen/UserCode';

const requiredPart = t.interface({
  animalId: PositiveLongSchema,
  breederName: BreederNameSchema,
  dead: t.boolean,
  earTag: EarTagWithoutCountrySchema,
  id: PositiveLongSchema,
  lactation: PositiveIntegerSchema,
  ratingTs: DateTimeSchema,
  stableName: StableNameSchema,
  sumOfApprovedMeasurements: PositiveOrZeroLongSchema,
  type: AnimalInPlanTypeSchema,
  userCode: UserCodeSchema,
});

export const RatingToApproveSchema = excess(requiredPart);

export interface RatingToApprove extends t.TypeOf<typeof RatingToApproveSchema> {}
