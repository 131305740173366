import {Options} from 'cmsch-fe-library';

import {Tenant} from 'api/gen/Tenant';
import {TFunction} from 'app/translations';

const holsteinSecondOption = 85;
const fleckviehSecondOption = 87;

export const getBreedOptions = (
    t: TFunction<'breeding/AnimalsTable'>,
    tenant: Tenant | undefined,
): Options<number> => tenant
    ? [
        {label: t('breedOptions.firstOption'), value: 75},
        {
            label: t(tenant === 'HOLSTEIN'
                ? 'breedOptions.secondOptionHolstein'
                : 'breedOptions.secondOptionFleckvieh'),
            value: tenant === 'HOLSTEIN' ? holsteinSecondOption : fleckviehSecondOption,
        },
        {label: t('breedOptions.all'), value: 1},
    ]
    : [];
