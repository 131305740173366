// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractTableFilter, AbstractTableFilterSchema } from 'api/gen/AbstractTableFilter';

const requiredPart = t.interface({
  tag: t.literal('LESS_THAN_EARTAG'),
  countryCode: t.union([t.string, t.null]),
  field: t.string,
  value: t.Integer,
});

export const LessThanEarTagFilterSchema = excess(t.intersection([AbstractTableFilterSchema.type, requiredPart]));

export interface LessThanEarTagFilter extends t.TypeOf<typeof LessThanEarTagFilterSchema> {}
