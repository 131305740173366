// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FleckviehMeasurementUpdate, FleckviehMeasurementUpdateSchema } from 'api/gen/FleckviehMeasurementUpdate';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { HolsteinMeasurementUpdate, HolsteinMeasurementUpdateSchema } from 'api/gen/HolsteinMeasurementUpdate';

export const MeasurementUpdateSchema = t.union([FleckviehMeasurementUpdateSchema, HolsteinMeasurementUpdateSchema]);

export type MeasurementUpdate = t.TypeOf<typeof MeasurementUpdateSchema>;
