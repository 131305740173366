import {putAll, showBeError, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {GetStablesWithBreedingsAction, unknownAnimalAction} from '../action';

function* execute(_: GetStablesWithBreedingsAction): SagaIterator {
    const response = yield* call(Api.findAllStablesWithBreedings);

    if (response.isSuccess) {
        yield* put(unknownAnimalAction.getStablesWithBreedingsResult(response.data));
    } else {
        yield putAll(showBeError(response, t('unknownAnimals')('stablesWithBreedings')));
    }
}

export function* getStablesWithBreedings(): SagaIterator {
    yield takeLatestF('unknownAnimals/GET_STABLES_WITH_BREEDINGS', execute);
}
