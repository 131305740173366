import {Table, mergeObjects} from 'cmsch-fe-library';
import {isNull} from 'lodash/fp';
import React, {FC, memo, useMemo} from 'react';
import {opt} from 'ts-opt';

import {Import} from 'api/gen/Import';
import {useOurTranslation} from 'app/translations';

import {getColumns, ImportTableType} from './columns';

interface Props {
    imports: Array<Import> | null;
    loading: boolean;
}

const ImportTableBase: FC<Props> = props => {
    const {imports, loading} = props;

    const {t} = useOurTranslation('importKkut/table');

    const data = useMemo(() =>
        opt(imports).orElse([]).map((x, id): ImportTableType => mergeObjects(x, {id}))
    , [imports]);

    return (
        <Table
            tableId="import-kkut"
            columns={getColumns(t)}
            rows={data}
            emptyTableMessages={{noRows: t('noImportsFound')}}
            scroll={{x: true}}
            loading={isNull(imports) || loading}
        />
    );
};

export const ImportTable = memo(ImportTableBase);
