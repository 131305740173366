import {downloadFileFromResponse, putAll, showBeError, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {GetInvoicingDocumentsAction} from '../action';

const defaultPrintFilename = 'podklady_k_fakturaci.csv';

function* execute(action: GetInvoicingDocumentsAction): SagaIterator {
    const {bullId} = action.payload;

    yield* put(layoutAction.setItemLoading('exportInvoicingDocuments', true));

    const response = yield* call(Api.getInvoicingDocumentsForBull, {bullId});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response, defaultPrintFilename);
    } else {
        yield putAll(showBeError(response, t('bull/sagas')('exportInvoicingDocuments')));
    }

    yield* put(layoutAction.setItemLoading('exportInvoicingDocuments', false));
}

export function* getInvoicingDocumentsSaga(): SagaIterator {
    yield takeLatestF('bull/GET_INVOICING_DOCUMENTS', execute);
}
