import {NullableKeys} from 'cmsch-fe-library';

import {FleckviehMeasurementUpdate} from 'api/gen/FleckviehMeasurementUpdate';

export const repairFleckviehMeasurementForm = 'repairFleckviehMeasurement';

export type RepairFleckviehMeasurementFormValues = NullableKeys<FleckviehMeasurementUpdate>;

export const repairFleckviehMeasurementInitialValues: RepairFleckviehMeasurementFormValues = {
    earTag: null,
    lactation: null,
    properties: null,
    ratingDate: null,
    tag: 'FleckviehMeasurementUpdate',
};
