// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractTableFilter, AbstractTableFilterSchema } from 'api/gen/AbstractTableFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  tag: t.literal('GREATER_THAN_BREED'),
  field: t.string,
  value: PositiveIntegerSchema,
});

export const GreaterThanBreedFilterSchema = excess(t.intersection([AbstractTableFilterSchema.type, requiredPart]));

export interface GreaterThanBreedFilter extends t.TypeOf<typeof GreaterThanBreedFilterSchema> {}
