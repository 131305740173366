// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// The name of a KKUT file.
// Example: "kkut12.txt"

export const kkutFileMinLength = 1;
export const kkutFileMaxLength = 255;

export const KkutFileSchema = t.refinement(t.string, n => n.length >= kkutFileMinLength && n.length <= kkutFileMaxLength, 'KkutFile');

export type KkutFile = string;
