// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// The first calve days of an animal.
// Example: 27

export const calveDaysMaximum = 31.0;

export const CalveDaysSchema = t.refinement(t.Integer, x => x <= calveDaysMaximum, 'CalveDays');

export type CalveDays = t.TypeOf<typeof CalveDaysSchema>;
