// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractMeasurementAnimal, AbstractMeasurementAnimalSchema } from 'api/gen/AbstractMeasurementAnimal';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UdderPurity, UdderPuritySchema } from 'api/gen/UdderPurity';

const requiredPart = t.interface({
  tag: t.literal('FleckviehMeasurementAnimal'),
  adjustedExtremities: t.union([PositiveIntegerSchema, t.null]),
  adjustedMusculature: t.union([PositiveIntegerSchema, t.null]),
  adjustedTotal: t.union([PositiveIntegerSchema, t.null]),
  adjustedUdder: t.union([PositiveIntegerSchema, t.null]),
  ankleJoint: t.union([PositiveIntegerSchema, t.null]),
  backAngle: t.union([PositiveIntegerSchema, t.null]),
  backHeight: t.union([PositiveIntegerSchema, t.null]),
  backLength: t.union([PositiveIntegerSchema, t.null]),
  backWidth: t.union([PositiveIntegerSchema, t.null]),
  bodyDepth: t.union([PositiveIntegerSchema, t.null]),
  bodyLength: t.union([PositiveIntegerSchema, t.null]),
  calculatedExtremities: t.union([PositiveIntegerSchema, t.null]),
  calculatedFrame: t.union([PositiveIntegerSchema, t.null]),
  calculatedMusculature: t.union([PositiveIntegerSchema, t.null]),
  calculatedTotal: t.union([PositiveIntegerSchema, t.null]),
  calculatedUdder: t.union([PositiveIntegerSchema, t.null]),
  chestCircumference: t.union([PositiveIntegerSchema, t.null]),
  colorType: t.union([PositiveIntegerSchema, t.null]),
  edematicUdder: t.union([PositiveOrZeroIntegerSchema, t.null]),
  fitness: t.union([t.number, t.null]),
  frontExtremitiesOpenPosture: t.union([PositiveOrZeroIntegerSchema, t.null]),
  frontTeatsPlacement: t.union([PositiveIntegerSchema, t.null]),
  frontTeatsSideways: t.union([PositiveOrZeroIntegerSchema, t.null]),
  frontUdderAttachmentAngle: t.union([PositiveIntegerSchema, t.null]),
  frontUdderLength: t.union([PositiveIntegerSchema, t.null]),
  funnelTeats: t.union([PositiveOrZeroIntegerSchema, t.null]),
  gradualUdder: t.union([PositiveOrZeroIntegerSchema, t.null]),
  headColor: t.union([PositiveOrZeroIntegerSchema, t.null]),
  hoovesBackHeight: t.union([PositiveIntegerSchema, t.null]),
  hoovesTorsion: t.union([PositiveOrZeroIntegerSchema, t.null]),
  lastMilkingAt: PositiveIntegerSchema,
  looseLoins: t.union([PositiveOrZeroIntegerSchema, t.null]),
  looseShoulderBlade: t.union([PositiveOrZeroIntegerSchema, t.null]),
  mottleType: t.union([PositiveIntegerSchema, t.null]),
  musculature: t.union([PositiveIntegerSchema, t.null]),
  nervousness: t.union([PositiveOrZeroIntegerSchema, t.null]),
  openedHooves: t.union([PositiveOrZeroIntegerSchema, t.null]),
  pastern: t.union([PositiveIntegerSchema, t.null]),
  posture: t.union([PositiveOrZeroIntegerSchema, t.null]),
  rearPosture: t.union([PositiveIntegerSchema, t.null]),
  rearTeatsPlacement: t.union([PositiveIntegerSchema, t.null]),
  rearTeatsPosition: t.union([PositiveIntegerSchema, t.null]),
  rearUdderAttachment: t.union([PositiveIntegerSchema, t.null]),
  rearUdderLength: t.union([PositiveIntegerSchema, t.null]),
  roofedBack: t.union([PositiveOrZeroIntegerSchema, t.null]),
  shortRearTeats: t.union([PositiveOrZeroIntegerSchema, t.null]),
  suspensoryLigament: t.union([PositiveIntegerSchema, t.null]),
  teatsForward: t.union([PositiveOrZeroIntegerSchema, t.null]),
  teatsLength: t.union([PositiveIntegerSchema, t.null]),
  teatsWidth: t.union([PositiveIntegerSchema, t.null]),
  udderDepth: t.union([PositiveIntegerSchema, t.null]),
  udderPurity: t.union([UdderPuritySchema, t.null]),
  weakBack: t.union([PositiveOrZeroIntegerSchema, t.null]),
  withersHeight: t.union([PositiveIntegerSchema, t.null]),
});

export const FleckviehMeasurementAnimalSchema = excess(t.intersection([AbstractMeasurementAnimalSchema.type, requiredPart]));

export interface FleckviehMeasurementAnimal extends t.TypeOf<typeof FleckviehMeasurementAnimalSchema> {}
