// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// A value of a proficiency test of an animal.
// Example: 15

export const proficiencyTestMaximum = 99.0;

export const ProficiencyTestSchema = t.refinement(t.Integer, x => x <= proficiencyTestMaximum, 'ProficiencyTest');

export type ProficiencyTest = t.TypeOf<typeof ProficiencyTestSchema>;
