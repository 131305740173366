// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


const requiredPart = t.interface({
  password: t.string,
  username: t.string,
});

export const UserLoginDetailsSchema = excess(requiredPart);

export interface UserLoginDetails extends t.TypeOf<typeof UserLoginDetailsSchema> {}
