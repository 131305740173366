/* eslint-disable import/no-unused-modules */
import {TableDataRequest} from 'cmsch-fe-library';
import {Opt} from 'ts-opt';

import {Breeder} from 'api/gen/Breeder';
import {Breeding} from 'api/gen/Breeding';
import {PageBreeder} from 'api/gen/PageBreeder';
import {Stable} from 'api/gen/Stable';
import {StableEmail} from 'api/gen/StableEmail';

export const breederAction = {
    getBreeders: (tableDataRequest: Opt<TableDataRequest<Breeder>>) => ({
        type: 'breeder/GET_BREEDERS',
        payload: {tableDataRequest},
    }) as const,
    getBreedersSuccess: (breederPage: Opt<PageBreeder>) => ({
        type: 'breeder/GET_BREEDERS_SUCCESS',
        payload: {breederPage},
    }) as const,
    getBreeder: (breederId: number) => ({
        type: 'breeder/GET_BREEDER',
        payload: {breederId},
    }) as const,
    getBreederSuccess: (breeder: Breeder) => ({
        type: 'breeder/GET_BREEDER_SUCCESS',
        payload: {breeder},
    }) as const,
    updateBreeder: (breederId: number) => ({
        type: 'breeder/UPDATE_BREEDER',
        payload: {breederId},
    }) as const,
    clearBreeder: () => ({
        type: 'breeder/CLEAR_BREEDER',
    }) as const,
    clearStable: () => ({
        type: 'breeder/CLEAR_STABLE',
    }) as const,
    clearShownBreedings: () => ({
        type: 'breeder/CLEAR_SHOWN_BREEDINGS',
    }) as const,
    updateBreedingArealRating: (breedingId: number, arealRating: boolean) => ({
        type: 'breeder/UPDATE_BREEDER_BREEDING_AREAL_RATING',
        payload: {breedingId, arealRating},
    }) as const,
    getStables: (breederId: number) => ({
        type: 'breeder/GET_STABLES',
        payload: {breederId},
    }) as const,
    getStablesSuccess: (stables: Array<Stable>) => ({
        type: 'breeder/GET_STABLES_SUCCESS',
        payload: {stables},
    }) as const,
    clearStables: () => ({
        type: 'breeder/CLEAR_STABLES',
    }) as const,
    getStable: (stableId: number) => ({
        type: 'breeder/GET_STABLE',
        payload: {stableId},
    }) as const,
    getStableSuccess: (stable: Stable) => ({
        type: 'breeder/GET_STABLE_SUCCESS',
        payload: {stable},
    }) as const,
    updateStable: (stableId: number, breederId: number) => ({
        type: 'breeder/UPDATE_STABLE',
        payload: {stableId, breederId},
    }) as const,
    getStableBreedings: (stableId: number) => ({
        type: 'breeder/GET_STABLE_BREEDINGS',
        payload: {stableId},
    }) as const,
    getStableBreedingsSuccess: (breedings: Array<Breeding>) => ({
        type: 'breeder/GET_STABLE_BREEDINGS_SUCCESS',
        payload: {breedings},
    }) as const,
    getStablesAlternativeEmails: (stableIds: Array<number>) => ({
        type: 'breeder/GET_STABLES_ALTERNATIVE_EMAILS',
        payload: {stableIds},
    }) as const,
    getStablesAlternativeEmailsSuccess: (emails: Array<StableEmail>) => ({
        type: 'breeder/GET_STABLES_ALTERNATIVE_EMAILS_SUCCESS',
        payload: {emails},
    }) as const,
    setEditedStableId: (stableId: Opt<number>) => ({
        type: 'breeder/SET_EDITED_STABLE_ID',
        payload: {stableId},
    }) as const,
};

export type GetBreedersAction = ReturnType<typeof breederAction.getBreeders>;
export type GetBreedersSuccessAction = ReturnType<typeof breederAction.getBreedersSuccess>;
export type GetStablesAction = ReturnType<typeof breederAction.getStables>;
export type GetStablesSuccessAction = ReturnType<typeof breederAction.getStablesSuccess>;
export type GetBreederAction = ReturnType<typeof breederAction.getBreeder>;
export type GetBreederSuccessAction = ReturnType<typeof breederAction.getBreederSuccess>;
export type UpdateBreederAction = ReturnType<typeof breederAction.updateBreeder>;
export type ClearBreederAction = ReturnType<typeof breederAction.clearBreeder>;
export type ClearShownBreedingsAction = ReturnType<typeof breederAction.clearShownBreedings>;
export type UpdateBreedingArealRatingAction = ReturnType<typeof breederAction.updateBreedingArealRating>;
export type ClearStableAction = ReturnType<typeof breederAction.clearStable>;
export type ClearStablesAction = ReturnType<typeof breederAction.clearStables>;
export type GetStableBreedingsAction = ReturnType<typeof breederAction.getStableBreedings>;
export type GetStableBreedingsSuccessAction = ReturnType<typeof breederAction.getStableBreedingsSuccess>;
export type GetStableAction = ReturnType<typeof breederAction.getStable>;
export type GetStableSuccessAction = ReturnType<typeof breederAction.getStableSuccess>;
export type UpdateStableAction = ReturnType<typeof breederAction.updateStable>;
export type GetStablesAlternativeEmailsAction = ReturnType<typeof breederAction.getStablesAlternativeEmails>;
export type GetStablesAlternativeEmailsSuccessAction =
    ReturnType<typeof breederAction.getStablesAlternativeEmailsSuccess>;
export type SetEditedStableIdAction = ReturnType<typeof breederAction.setEditedStableId>;

export type BreederAction =
    | GetBreedersAction
    | GetBreedersSuccessAction
    | GetStablesAction
    | GetStablesSuccessAction
    | GetBreederAction
    | GetBreederSuccessAction
    | UpdateBreederAction
    | ClearBreederAction
    | ClearShownBreedingsAction
    | UpdateBreedingArealRatingAction
    | ClearStableAction
    | ClearStablesAction
    | GetStableBreedingsAction
    | GetStableBreedingsSuccessAction
    | GetStableAction
    | GetStableSuccessAction
    | UpdateStableAction
    | GetStablesAlternativeEmailsAction
    | GetStablesAlternativeEmailsSuccessAction
    | SetEditedStableIdAction
    ;
