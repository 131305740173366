// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-app-measurement-components-measurements-action-panel-___styles__panel___jW3t2{display:flex;align-items:center;justify-content:flex-end}@media(max-width: 480px){.src-app-measurement-components-measurements-action-panel-___styles__panel___jW3t2{gap:.5rem;flex-direction:column}}", "",{"version":3,"sources":["webpack://./src/app/measurement/components/measurements-action-panel/styles.sass"],"names":[],"mappings":"AAEA,mFACI,YAAA,CACA,kBAAA,CACA,wBAAA,CAGJ,yBACI,mFACI,SAAA,CACA,qBAAA,CAAA","sourcesContent":["@import '../../../../styles/variables'\n\n.panel\n    display: flex\n    align-items: center\n    justify-content: flex-end\n\n\n@media (max-width: $screen-xs)\n    .panel\n        gap: 0.5rem\n        flex-direction: column\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "src-app-measurement-components-measurements-action-panel-___styles__panel___jW3t2"
};
export default ___CSS_LOADER_EXPORT___;
