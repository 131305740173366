import {inflectWord} from 'cmsch-fe-library';
import {compact} from 'lodash/fp';
import {optEmptyString} from 'ts-opt';

import {TFunction} from 'app/translations';
import {inflectAnimalWord} from 'app/utils/inflect-animal-word';

const inflectBreedersWord = (
    count: number,
    t: TFunction<'planning/plan'>,
): string => inflectWord(
    t('inflectBreeders.first'),
    t('inflectBreeders.second'),
    t('inflectBreeders.third'),
    count,
);

const inflectStablesWord = (
    count: number,
    t: TFunction<'planning/plan'>,
): string => inflectWord(
    t('inflectStables.first'),
    t('inflectStables.second'),
    t('inflectStables.third'),
    count,
);

export const getPlanTitle = (
    t: TFunction<'planning/plan'>,
    animalsCount: number,
    breedingCount: number,
    stablesCount: number,
): string =>
    optEmptyString(
        compact([
            `${animalsCount} ${inflectAnimalWord(animalsCount || 0)}`,
            breedingCount > 0 && `${breedingCount} ${inflectBreedersWord(breedingCount, t)}`,
            stablesCount > 0 && `${stablesCount} ${inflectStablesWord(stablesCount, t)}`,
        ]).join(' | '),
    ).map(x => [
        t('title'),
        x,
    ].join(' ')).orElse(t('title'));
