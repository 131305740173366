import {showBeError, putAll, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';
import {
    convertTableDataParamsToDto,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {bullAction, GetInvoicesDaughtersAction} from '../action';

function* execute({payload: {tableDataRequest, bullId}}: GetInvoicesDaughtersAction): SagaIterator {
    yield* put(tablesAction.tableDataFetchStart('invoicesDaughters'));
    const tableState = yield* select(simpleTableSelector.tableState('invoicesDaughters'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'invoicesDaughters',
        tableDataRequest: tableDataRequest.orElse({action: 'reset'}),
        tableState,
    });

    const {filters, params} = convertTableDataParamsToDto({
        tableDataParams,
        fulltextFilterFields: ['shortEarTag', 'stableName', 'breederName'],
    });
    const response = yield* call(Api.findAllDaughtersForInvoicingDocuments, filters, params, {bullId});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('bull/sagas')('getBullAnimals')));
    } else {
        yield* put(tablesAction.tableDataFetchSuccess('invoicesDaughters', tableDataParams));
        yield* put(bullAction.getInvoicesDaughtersSuccess(response.data));
    }

    yield* put(layoutAction.setItemLoading('getInvoicesDaughters', false));
}

export function* getInvokesDaughtersSaga(): SagaIterator {
    yield takeLatestF('bull/GET_INVOICES_DAUGHTER', execute);
}
