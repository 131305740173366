import {takeLatestF, putAll, showBeError} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {simpleUserSelector} from 'app/user';
import {TableId} from 'utils/table-config';
import {
    convertTableDataParamsToDto,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {GetRatingsOfAnimalAction, measurementAction} from '../action';

function* execute(action: GetRatingsOfAnimalAction): SagaIterator {
    const {tableDataRequest, animalId} = action.payload;

    if (tableDataRequest.isEmpty) {
        return yield* put(measurementAction.getRatingsOfAnimalSuccess(none));
    }

    const tenant = yield* select(simpleUserSelector.tenant);

    const tableId: TableId = tenant === 'HOLSTEIN' ? 'ratingsOfHolsteinAnimal' : 'ratingsOfFleckviehAnimal';
    yield* put(tablesAction.tableDataFetchStart(tableId));
    const tableState = yield* select(simpleTableSelector.tableState(tableId));

    const tableDataParams = getRequestedTablePageParams({
        tableId,
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    },
    );

    const {filters, params} = convertTableDataParamsToDto({tableDataParams});

    const response = yield* call(Api.findAllMeasurementsForAnimal, filters, params, {animalId});

    if (response.isSuccess) {
        yield* put(measurementAction.getRatingsOfAnimalSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess(tableId, tableDataParams));
    } else {
        yield putAll(showBeError(response, t('measurement/detail')('animalMeasurements')));
    }
}

export function* getRatingsOfAnimalSaga(): SagaIterator {
    yield takeLatestF('measurement/GET_RATINGS_OF_ANIMAL', execute);
}
