// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// A phone number.
// Example: "+ 420 764 349 314"

export const phoneNumberMinLength = 1;
export const phoneNumberMaxLength = 255;

export const PhoneNumberSchema = t.refinement(t.string, n => n.length >= phoneNumberMinLength && n.length <= phoneNumberMaxLength, 'PhoneNumber');

export type PhoneNumber = string;
