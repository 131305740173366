// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// Example: 1

export const positiveLongMinimum = 1.0;

export const PositiveLongSchema = t.refinement(t.Integer, x => x >= positiveLongMinimum, 'PositiveLong');

export type PositiveLong = t.TypeOf<typeof PositiveLongSchema>;
