import {TableDataParams, downloadFileFromResponse, putAll, showBeError, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {MeasurementView} from 'api/gen/MeasurementView';
import {t} from 'app/translations';
import {simpleUserSelector} from 'app/user/model/selector';
import {layoutAction} from 'common/layout';
import {TableId} from 'utils/table-config';
import {convertTableDataParamsToDto, simpleTableSelector} from 'utils/tables';

import {ExportMeasuredAction} from '../action';

function* getTableDataParams(): SagaIterator<TableDataParams<MeasurementView>> {
    const tenant = yield* select(simpleUserSelector.tenant);
    const tableId: TableId = tenant === 'HOLSTEIN' ? 'databaseOfMeasuredHolstein' : 'databaseOfMeasuredFleckvieh';
    const tableState = yield* select(simpleTableSelector.tableState(tableId));

    return opt(tableState).prop('tableDataParams').orCrash('missing table params');
}

function* execute(_: ExportMeasuredAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('exportMeasured', true));

    const tableDataParams = yield* call(getTableDataParams);
    const {filters, params: {sort}} = convertTableDataParamsToDto({
        tableDataParams,
        fulltextFilterFields: ['earTag', 'breedingNumber', 'stableName', 'breederName'],
    });

    const response = yield* call(Api.exportAllApproved, filters, {sort});

    if (response.isSuccess) {
        yield* call(downloadFileFromResponse, response);
    } else {
        yield putAll(showBeError(response, t('databaseOfMeasured/messages')('exportMeasuredTitle')));
    }

    yield* put(layoutAction.setItemLoading('exportMeasured', false));
}

export function* exportMeasuredSaga(): SagaIterator {
    yield takeLatestF('databaseOfMeasured/EXPORT_MEASURED', execute);
}
