import {showBeError, putAll, showSuccess, takeLatestF, extractFormErrorsFromResponse} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt, optEmptyString} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {UpdateBull} from 'api/gen/UpdateBull';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {bullFormName} from '../../types/edit-bull-form-values';
import {bullAction, EditBullAction} from '../action';
import {simpleBullSelector} from '../selector';

const te = t('bull/sagas');

function* execute({payload: {bull}}: EditBullAction): SagaIterator {
    const bullId = (yield* select(simpleBullSelector.bullInEdit)).orCrash('no ratingInEdit');

    const updateBull: UpdateBull = {
        name: optEmptyString(bull.name).orNull(),
        marked: bull.marked,
        note: optEmptyString(bull.note).orNull(),
    };
    const response = yield* call(Api.updateBull, updateBull, {bullId});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, te('editBull')));
        yield* put(formHelpers.stopSubmit(bullFormName, extractFormErrorsFromResponse(response)));
    } else {
        yield* put(showSuccess(te('editBull'), te('bullWasEdited')));
        yield* put(layoutAction.setModalVisibility('bullEdit', false));
        yield* put(bullAction.getBulls(opt({action: 'refresh'})));
    }
}

export function* editBullSaga(): SagaIterator {
    yield takeLatestF('bull/EDIT_BULL', execute);
}
