// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';
import {StringPatternSchema} from 'cmsch-fe-library';


// The multiple e-mails schema.
// Example: "jim.beam@drinks.org; jack.daniels@sobers.com"

export const emailListRegexGen = (): RegExp => new RegExp("^[\\w%+.-]+@[\\w.-]+\\.[A-Za-z]{2,}(\\s*;\\s*[\\w%+.-]+@[\\w.-]+\\.[A-Za-z]{2,})*$");

export const emailListMinLength = 6;
export const emailListMaxLength = 255;

export const EmailListSchema = t.refinement(StringPatternSchema<EmailList>(emailListRegexGen()), n => n.length >= emailListMinLength && n.length <= emailListMaxLength, 'EmailList');

export type EmailList = string;
