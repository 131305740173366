import {ActionSettings} from 'cmsch-fe-library';
import {useCallback, useMemo} from 'react';

import {BullView} from 'api/gen/BullView';
import {useOurTranslation} from 'app/translations';
import {LoadingItemId} from 'common/layout/types/loading-item-id';

interface UseGetActionSettings {
    isAdmin: boolean;
    loadingItems: Array<LoadingItemId>;
    onOpenEditClick(bullId: number): void;
    exportBullDaughters(bullId: number): void;
    goTo(path: string): void;
}

// eslint-disable-next-line max-lines-per-function
export const useGetActionSettings = ({
    isAdmin,
    loadingItems,
    onOpenEditClick,
    exportBullDaughters,
    goTo,
}: UseGetActionSettings): ActionSettings<BullView> => {
    const {t, tCommon} = useOurTranslation('bull/bullTable');

    const getExportBullDaughtersTitle = useCallback(() =>
        loadingItems.includes('exportBullDaughters')
            ? tCommon('actionPending')
            : t('exportDaughters')
    , [loadingItems, t, tCommon]);

    const goToDaughters = useCallback((bullId: number) => {
        goTo(`/bulls/daughters/${bullId}`);
    }, [goTo]);

    const isExportBullDaughtersDisabled = useCallback((_rowId: number, bull: BullView) =>
        loadingItems.includes('exportBullDaughters') || bull.numOfMeasuredDaughters === 0
    , [loadingItems]);

    const getExportBullDaughtersDisabledTitle = useCallback((_rowId: number, bull: BullView) =>
        bull.numOfMeasuredDaughters === 0 ? t('exportBullDaughtersIsDisabled') : ''
    , [t]);

    const isDaughtersOverviewDisabled = useCallback((_rowId: number, bull: BullView) =>
        bull.numOfDaughtersToMeasure === 0
    , []);

    return useMemo((): ActionSettings<BullView> => ({
        onEdit: isAdmin ? onOpenEditClick : undefined,
        extraActions: [
            {
                id: 'exportBullDaughters',
                icon: 'downloadOutlined',
                title: getExportBullDaughtersTitle,
                callback: exportBullDaughters,
                disabled: isExportBullDaughtersDisabled,
                disabledTitle: getExportBullDaughtersDisabledTitle,
                testId: 'export-bull-daughters-button',
            },
            {
                id: 'daughtersOverview',
                icon: 'zoomInOutlined',
                title: t('daughtersOverview'),
                callback: goToDaughters,
                testId: 'daughters-overview-button',
                disabled: isDaughtersOverviewDisabled,
            },
        ],
    }), [
        exportBullDaughters,
        getExportBullDaughtersDisabledTitle,
        getExportBullDaughtersTitle,
        goToDaughters,
        isAdmin,
        isDaughtersOverviewDisabled,
        isExportBullDaughtersDisabled,
        onOpenEditClick,
        t,
    ]);
};
