// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// The encoded measurement.
// Example: "20300012525930406052001031371360490490781825155346656664767566076074096075120"

export const encodedMeasurementMinLength = 1;
export const encodedMeasurementMaxLength = 255;

export const EncodedMeasurementSchema = t.refinement(t.string, n => n.length >= encodedMeasurementMinLength && n.length <= encodedMeasurementMaxLength, 'EncodedMeasurement');

export type EncodedMeasurement = string;
