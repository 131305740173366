// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserCode, UserCodeSchema } from 'api/gen/UserCode';

const requiredPart = t.interface({
  id: PositiveLongSchema,
  lactation: PositiveIntegerSchema,
  ratingDate: DateSchema,
  userCode: UserCodeSchema,
});

export const MeasurementSearchSchema = excess(requiredPart);

export interface MeasurementSearch extends t.TypeOf<typeof MeasurementSearchSchema> {}
