// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FirstName, FirstNameSchema } from 'api/gen/FirstName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LastName, LastNameSchema } from 'api/gen/LastName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Password, PasswordSchema } from 'api/gen/Password';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Role, RoleSchema } from 'api/gen/Role';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UserCode, UserCodeSchema } from 'api/gen/UserCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Username, UsernameSchema } from 'api/gen/Username';

const requiredPart = t.interface({
  code: UserCodeSchema,
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  password: PasswordSchema,
  role: RoleSchema,
  username: UsernameSchema,
});

export const CreateUserSchema = excess(requiredPart);

export interface CreateUser extends t.TypeOf<typeof CreateUserSchema> {}
