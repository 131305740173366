// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UdderPurity, UdderPuritySchema } from 'api/gen/UdderPurity';

const requiredPart = t.interface({
  adjustedExtremities: t.Integer,
  adjustedMusculature: t.Integer,
  adjustedTotal: t.Integer,
  adjustedUdder: t.Integer,
  ankleJoint: t.Integer,
  backAngle: t.Integer,
  backHeight: t.Integer,
  backLength: t.Integer,
  backWidth: t.Integer,
  bodyDepth: t.Integer,
  bodyLength: t.Integer,
  calculatedExtremities: t.Integer,
  calculatedFrame: t.Integer,
  calculatedMusculature: t.Integer,
  calculatedTotal: t.Integer,
  calculatedUdder: t.Integer,
  chestCircumference: t.Integer,
  colorType: t.Integer,
  edematicUdder: t.Integer,
  fitness: t.number,
  frontExtremitiesOpenPosture: t.Integer,
  frontTeatsPlacement: t.Integer,
  frontTeatsSideways: t.Integer,
  frontUdderAttachmentAngle: t.Integer,
  frontUdderLength: t.Integer,
  funnelTeats: t.Integer,
  gradualUdder: t.Integer,
  headColor: t.Integer,
  hoovesBackHeight: t.Integer,
  hoovesTorsion: t.Integer,
  lastMilkingAt: PositiveIntegerSchema,
  looseLoins: t.Integer,
  looseShoulderBlade: t.Integer,
  mottleType: t.Integer,
  musculature: t.Integer,
  narrowBack: t.union([t.Integer, t.null]),
  narrowChest: t.union([t.Integer, t.null]),
  nervousness: t.Integer,
  openedHooves: t.Integer,
  pastern: t.Integer,
  posture: t.Integer,
  rearPosture: t.Integer,
  rearTeatsPlacement: t.Integer,
  rearTeatsPosition: t.Integer,
  rearUdderAttachment: t.Integer,
  rearUdderLength: t.Integer,
  roofedBack: t.Integer,
  shortRearTeats: t.Integer,
  suspensoryLigament: t.Integer,
  teatsForward: t.Integer,
  teatsLength: t.Integer,
  teatsWidth: t.Integer,
  udderDepth: t.Integer,
  udderPurity: UdderPuritySchema,
  weakBack: t.Integer,
  withersHeight: t.union([t.Integer, t.null]),
});

export const FleckviehMeasurementPropertiesSchema = excess(requiredPart);

export interface FleckviehMeasurementProperties extends t.TypeOf<typeof FleckviehMeasurementPropertiesSchema> {}
