import {putAll, showBeError, showSuccess, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {State} from 'app/setup';
import {t} from 'app/translations';
import {unknownAnimalAction} from 'app/unknown-animal';
import {layoutAction} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {AssignRatingAction, measurementAction} from '../action';
import {simpleMeasurementSelector} from '../selector';

const translate = t('measurement/messages');

const getEarTag = (state: State): string => {
    const values = formHelpers.formValues('selectAnimal')(state).orCrash('missing selectAnimal form values');

    return opt(values).prop('earTag').orCrash('missing earTag');
};

function* execute(_: AssignRatingAction): SagaIterator {
    const ratingId = (yield* select(simpleMeasurementSelector.ratingInEditId)).orCrash('no ratingInEdit');

    const earTag = yield* select(getEarTag);
    const response = yield* call(Api.assignAnimalToRating, {earTag}, {ratingId});

    if (response.isSuccess) {
        yield* put(layoutAction.setModalVisibility('editRating', false));
        yield* put(formHelpers.reset('selectAnimal'));
        yield* put(measurementAction.getMeasurements(opt({action: 'refresh'})));
        yield* put(unknownAnimalAction.getUnknownAnimals());
        yield* put(measurementAction.setRatingInEdit());
        yield* put(measurementAction.setEditedRating(opt(ratingId)));
        yield* put(showSuccess(
            translate('assignRatingTitle'),
            translate('assignRatingSuccess', {earTag}),
            false,
            'assign-rating-success',
        ));
    } else {
        yield putAll(showBeError(response, translate('assignRatingTitle')));
    }
}

export function* assignRatingSaga(): SagaIterator {
    yield takeLatestF('measurement/ASSIGN_RATING', execute);
}
