import {RatingWithUnknownBreeding} from 'api/gen/RatingWithUnknownBreeding';
import {StableWithBreedings} from 'api/gen/StableWithBreedings';

export const unknownAnimalAction = {
    getUnknownAnimals: () => ({
        type: 'unknownAnimals/GET_UNASSIGNED_ANIMALS',
    }) as const,
    getUnknownAnimalsResult: (animals: Array<RatingWithUnknownBreeding>) => ({
        type: 'unknownAnimals/GET_UNASSIGNED_ANIMALS_RESULT',
        payload: {animals},
    }) as const,
    clearUnknownAnimals: () => ({
        type: 'unknownAnimals/CLEAR_UNASSIGNED_ANIMALS',
    }) as const,
    getStablesWithBreedings: () => ({
        type: 'unknownAnimals/GET_STABLES_WITH_BREEDINGS',
    }) as const,
    getStablesWithBreedingsResult: (stables: Array<StableWithBreedings>) => ({
        type: 'unknownAnimals/GET_STABLES_WITH_BREEDINGS_RESULT',
        payload: {stables},
    }) as const,
    selectAnimalToAssign: (animal: RatingWithUnknownBreeding) => ({
        type: 'unknownAnimals/SELECT_ANIMAL_TO_ASSIGN',
        payload: {animal},
    }) as const,
    resetAnimalToAssign: () => ({
        type: 'unknownAnimals/RESET_ANIMAL_TO_ASSIGN',
    }) as const,
    assignAnimalToBreeding: () => ({
        type: 'unknownAnimals/ASSIGN_ANIMAL_TO_BREEDING',
    }) as const,
};

export type GetUnknownAnimalsAction = ReturnType<typeof unknownAnimalAction.getUnknownAnimals>;
export type GetUnknownAnimalsResultAction = ReturnType<typeof unknownAnimalAction.getUnknownAnimalsResult>;
export type ClearUnknownAnimalsAction = ReturnType<typeof unknownAnimalAction.clearUnknownAnimals>;
export type GetStablesWithBreedingsAction = ReturnType<typeof unknownAnimalAction.getStablesWithBreedings>;
export type GetStablesWithBreedingsResultAction = ReturnType<typeof unknownAnimalAction.getStablesWithBreedingsResult>;
export type SelectAnimalToAssignAction = ReturnType<typeof unknownAnimalAction.selectAnimalToAssign>;
export type ResetAnimalToAssignAction = ReturnType<typeof unknownAnimalAction.resetAnimalToAssign>;
export type AssignAnimalToBreedingAction = ReturnType<typeof unknownAnimalAction.assignAnimalToBreeding>;

export type UnknownAnimalAction =
    | GetUnknownAnimalsAction
    | GetUnknownAnimalsResultAction
    | ClearUnknownAnimalsAction
    | GetStablesWithBreedingsAction
    | GetStablesWithBreedingsResultAction
    | SelectAnimalToAssignAction
    | ResetAnimalToAssignAction
    | AssignAnimalToBreedingAction
    ;
