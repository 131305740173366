import {TypedFormSectionInjectedProps} from 'cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairFleckviehMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairFleckviehMeasurementFormValues, 'properties'>;

const FrameDefectsFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="narrowChest"
                label={tCommon('narrowChest')}
            />
            <Fields.NumberInput
                name="weakBack"
                label={tCommon('weakBack')}
                isRequired
            />
            <Fields.NumberInput
                name="looseLoins"
                label={tCommon('looseLoins')}
                isRequired
            />
            <Fields.NumberInput
                name="roofedBack"
                label={tCommon('roofedBack')}
                isRequired
            />
            <Fields.NumberInput
                name="narrowBack"
                label={tCommon('narrowBack')}
            />
        </Fragment>
    );
};

export const FrameDefectsFormSection = memo(FrameDefectsFormSectionBase);
