import {extractVersion, formatDatetime} from 'cmsch-fe-library';
import React, {FC, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Config} from 'app/config';
import {useOurTranslation} from 'app/translations';
import {Ant, AntEmpty} from 'common/ant';
import {Content, LoaderWrapper, PageHeader, simpleLayoutSelector} from 'common/layout';

import {AppInfoRow} from '../components';
import {adminAction, simpleAdminSelector} from '../model';

const feLibraryVersion = extractVersion(Config.feLibraryVersion).orCrash('Failed to extract fe-library version');

const AppInfoBase: FC = () => {
    const appInfo = useSelector(simpleAdminSelector.appInfo);
    const loading = useSelector(simpleLayoutSelector.isItemLoading('appInfo'));
    const {t} = useOurTranslation('admin/AppInfo');

    const dispatch = useDispatch();
    const getAppInfo = useCallback(() => {
        dispatch(adminAction.getAppInfo());
    }, [dispatch]);

    useEffect(() => {
        getAppInfo();
    }, [getAppInfo]);

    return appInfo.map(applicationInfo => (
        <Content key="app-info">
            <PageHeader title={t('title')} />

            <LoaderWrapper loading={loading}>
                <Ant.Divider orientation="left">
                    {t('frontend')}
                </Ant.Divider>
                <AppInfoRow
                    rowName={t('version')}
                    rowValue={Config.version}
                />
                <AppInfoRow
                    rowName={`${t('library')} cmsch-fe-library`}
                    rowValue={feLibraryVersion}
                />
                <Ant.Divider orientation="left">
                    {t('backend')}
                </Ant.Divider>
                <AppInfoRow
                    rowName={t('version')}
                    rowValue={applicationInfo.appVersion}
                />
                <AppInfoRow
                    rowName={t('dbVersion')}
                    rowValue={applicationInfo.dbVersion}
                />
                {applicationInfo.libraries?.map(library => (
                    <AppInfoRow
                        key={library.name}
                        rowName={`${t('library')} ${library.name}`}
                        rowValue={library.version}
                    />
                ))}
                <Ant.Divider orientation="left">
                    {t('deployment')}
                </Ant.Divider>
                <AppInfoRow
                    rowName={t('deployDate')}
                    rowValue={formatDatetime('datetime')(applicationInfo.buildTs).orElse('-')}
                />
            </LoaderWrapper>

        </Content>
    )).orElse(loading ? <LoaderWrapper loading /> : <AntEmpty description={t('noData')} />);
};

export const AppInfo = memo(AppInfoBase);
