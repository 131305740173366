// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedAlpha6, BreedAlpha6Schema } from 'api/gen/BreedAlpha6';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CalveAge, CalveAgeSchema } from 'api/gen/CalveAge';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Codex, CodexSchema } from 'api/gen/Codex';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DistrictName, DistrictNameSchema } from 'api/gen/DistrictName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ProficiencyTest, ProficiencyTestSchema } from 'api/gen/ProficiencyTest';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ShortEarTag, ShortEarTagSchema } from 'api/gen/ShortEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  breedAlpha6: t.union([BreedAlpha6Schema, t.null]),
  breederName: BreederNameSchema,
  codex: CodexSchema,
  daysSinceCalveDate: t.union([PositiveOrZeroIntegerSchema, t.null]),
  districtName: DistrictNameSchema,
  fathersLinReg: LineRegistrySchema,
  firstCalveAge: t.union([CalveAgeSchema, t.null]),
  id: PositiveLongSchema,
  isInBreedingWithMeasurement: t.boolean,
  marked: t.boolean,
  proficiencyTest: t.union([ProficiencyTestSchema, t.null]),
  shortEarTag: ShortEarTagSchema,
  stableName: StableNameSchema,
});

export const PlanningDaughterWithMarkSchema = excess(requiredPart);

export interface PlanningDaughterWithMark extends t.TypeOf<typeof PlanningDaughterWithMarkSchema> {}
