import {none, Opt} from 'ts-opt';

import {AppInfo} from 'api/gen/AppInfo';

export interface AdminState {
    appInfo: Opt<AppInfo>;
}

export const initialAdminState: AdminState = {
    appInfo: none,
};
