// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  breedingNumber: BreedingNumberSchema,
  stableName: StableNameSchema,
});

export const BasicBreedingSchema = excess(requiredPart);

export interface BasicBreeding extends t.TypeOf<typeof BasicBreedingSchema> {}
