// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedAlpha6, BreedAlpha6Schema } from 'api/gen/BreedAlpha6';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CalveAge, CalveAgeSchema } from 'api/gen/CalveAge';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Codex, CodexSchema } from 'api/gen/Codex';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroLong, PositiveOrZeroLongSchema } from 'api/gen/PositiveOrZeroLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ProficiencyTest, ProficiencyTestSchema } from 'api/gen/ProficiencyTest';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ShortEarTag, ShortEarTagSchema } from 'api/gen/ShortEarTag';

const requiredPart = t.interface({
  breed: t.union([BreedAlpha6Schema, t.null]),
  calveDate: t.union([DateSchema, t.null]),
  codex: CodexSchema,
  daysSinceCalveDate: t.union([PositiveOrZeroIntegerSchema, t.null]),
  fathersLinReg: t.union([LineRegistrySchema, t.null]),
  fathersName: t.union([AnimalNameSchema, t.null]),
  firstCalveAge: t.union([CalveAgeSchema, t.null]),
  id: PositiveLongSchema,
  marked: t.boolean,
  numOfDaughtersToMeasure: PositiveOrZeroLongSchema,
  numOfMeasuredDaughters: PositiveOrZeroLongSchema,
  numOfMeasuredDaughtersInBreeding: PositiveOrZeroLongSchema,
  proficiencyTest: t.union([ProficiencyTestSchema, t.null]),
  shortEarTag: ShortEarTagSchema,
});

export const PlanningAnimalSchema = excess(requiredPart);

export interface PlanningAnimal extends t.TypeOf<typeof PlanningAnimalSchema> {}
