// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Breeding, BreedingSchema } from 'api/gen/Breeding';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  breederName: BreederNameSchema,
  breedings: t.array(BreedingSchema),
  id: PositiveLongSchema,
  name: StableNameSchema,
});

export const StableWithBreedingsSchema = excess(requiredPart);

export interface StableWithBreedings extends t.TypeOf<typeof StableWithBreedingsSchema> {}
