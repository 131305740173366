import {TableColumn} from 'cmsch-fe-library';

import {RatingWithUnknownBreeding} from 'api/gen/RatingWithUnknownBreeding';
import {TFunction} from 'app/translations';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (t: TFunction<'common'>): Array<TableColumn<RatingWithUnknownBreeding>> => ([
    {
        field: 'earTag',
        column: t('earTag'),
        type: 'string',
        disableFilter: true,
    },
    {
        field: 'ratingTs',
        column: t('ratingDate'),
        type: 'date',
        disableFilter: true,
        dateFormat: 'datetime',
        dontShowTime: true,
    },
    {
        field: 'userCode',
        column: t('userCode'),
        type: 'string',
        disableFilter: true,
        align: 'right',
    },
    {
        field: 'lactation',
        column: t('lactation'),
        type: 'number',
        disableFilter: true,
    },
    {
        field: 'dead',
        column: t('dead'),
        type: 'boolean',
        disableFilter: true,
    },
]);
