// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// The name of a breeder.
// Example: "ZEAS NEDAKONICE,A.S."

export const breederNameMinLength = 1;
export const breederNameMaxLength = 255;

export const BreederNameSchema = t.refinement(t.string, n => n.length >= breederNameMinLength && n.length <= breederNameMaxLength, 'BreederName');

export type BreederName = string;
