import {toggleItem} from 'cmsch-fe-library';
import * as O from 'optics-ts';

import {AppAction} from 'app/setup';

import {BreedingState, initialBreedingState} from './state';

const breedingStateO = O.optic<BreedingState>();

export const breedingReducer = (
    state: BreedingState = initialBreedingState,
    action: AppAction,
): BreedingState => {
    switch (action.type) {
        case 'breeding/GET_BREEDINGS_SUCCESS': {
            const {breedingPage} = action.payload;

            return O.set(breedingStateO.prop('breedingPage'))(breedingPage)(state);
        }

        case 'breeding/RESET_BREEDING_DETAIL_SCREEN': {
            return O.set(breedingStateO.prop('breeding'))(null)(state);
        }

        case 'breeding/GET_BREEDING_RESULT': {
            const {breeding} = action.payload;

            return O.set(breedingStateO.prop('breeding'))(breeding)(state);
        }

        case 'breeding/GET_ANIMALS_IN_BREEDING_SUCCESS': {
            const {animalsInBreedingPage} = action.payload;

            return O.set(breedingStateO.prop('animalsInBreedingPage'))(animalsInBreedingPage)(state);
        }

        case 'breeding/GET_BREEDING_NUMBERS_RESULT': {
            const {payload: {breedingNumbers}} = action;

            return O.set(breedingStateO.prop('breedingNumbers'))(breedingNumbers)(state);
        }

        case 'breeding/TOGGLE_SELECTED_ANIMAL_IN_BREEDING': {
            const {animalId} = action.payload;

            return O.modify(breedingStateO.prop('selectedAnimalsInBreeding'))(toggleItem(animalId))(state);
        }

        case 'breeding/SET_SELECTED_ANIMALS_IN_BREEDING': {
            const {animalIds} = action.payload;

            return O.set(breedingStateO.prop('selectedAnimalsInBreeding'))(animalIds)(state);
        }

        case 'breeding/CLEAN_IDS_IN_LOADING':
            return O.set(breedingStateO.prop('fetchingIds'))([])(state);

        case 'breeding/ADD_IDS_TO_LOADING': {
            const {animalIds} = action.payload;
            const oldAnimalls = O.get(breedingStateO.prop('fetchingIds'))(state);
            const newAnmals = [...oldAnimalls, ...animalIds];

            return O.set(breedingStateO.prop('fetchingIds'))(newAnmals)(state);
        }

        case 'breeding/REMOVE_IDS_FROM_LOADING': {
            const {animalIds} = action.payload;
            const oldAnimals = O.get(breedingStateO.prop('fetchingIds'))(state);
            const newAnimals = oldAnimals.filter(animalId => !animalIds.includes(animalId));

            return O.set(breedingStateO.prop('fetchingIds'))(newAnimals)(state);
        }

        default:
            return state;
    }
};
