import {putAll, showBeError, takeLatestF} from 'cmsch-fe-library';
import * as routerActions from 'redux-first-history';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {GetAllApprovedMeasurementsAction, repairAction} from '../action';

function* execute(action: GetAllApprovedMeasurementsAction): SagaIterator {
    const {params} = action.payload;

    yield* put(layoutAction.setItemLoading('getAllApprovedMeasurements', true));

    const response = yield* call(Api.findAllApprovedByAnimal, params);

    if (response.isSuccess) {
        yield* put(repairAction.getAllApprovedMeasurementsSuccess(opt(response.data)));
        if (response.data.measurements?.length === 1) {
            yield* put(routerActions.push(`/repairs/${response.data.measurements[0].id}`));
        }
    } else {
        yield putAll(showBeError(response, t('repair/messages')('getAllApprovedMeasurements')));
    }

    yield* put(layoutAction.setItemLoading('getAllApprovedMeasurements', false));
}

export function* getAllApprovedMeasurementsSaga(): SagaIterator {
    yield takeLatestF('repair/GET_ALL_APPROVED_MEASUREMENTS', execute);
}
