import {putAll, showBeError, downloadFileFromResponse, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {PrintBreederPlanAction} from '../action';

function* execute({payload: {breederId}}: PrintBreederPlanAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('printBreeder', true));

    const response = yield* call(Api.exportPlanToPdfForBreeder, {breederId});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('planning/messages')('printBreederError')));
    } else {
        yield* call(downloadFileFromResponse, response, t('planning')('defaultPrintBreederFilename'));
    }

    yield* put(layoutAction.setItemLoading('printBreeder', false));
}

export function* printBreederPlanSaga(): SagaIterator {
    yield takeLatestF('planning/PRINT_BREEDER_PLAN', execute);
}
