// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractTableFilter, AbstractTableFilterSchema } from 'api/gen/AbstractTableFilter';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';

const requiredPart = t.interface({
  tag: t.literal('BEFORE_DATE'),
  field: t.string,
  value: DateSchema,
});

export const BeforeDateFilterSchema = excess(t.intersection([AbstractTableFilterSchema.type, requiredPart]));

export interface BeforeDateFilter extends t.TypeOf<typeof BeforeDateFilterSchema> {}
