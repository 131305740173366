// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';
import {StringPatternSchema} from 'cmsch-fe-library';


// A two-letter country code.
// Example: "CZ"

export const countryCodeRegexGen = (): RegExp => new RegExp("^[a-zA-Z]{2}$");

export const CountryCodeSchema = StringPatternSchema<CountryCode>(countryCodeRegexGen());

export type CountryCode = string;
