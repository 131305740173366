// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(min-width: 576px){.src-app-planning-components-plan-action-panel-___styles__secondaryButton___Ofw45{padding-left:4px}}@media(min-width: 1600px){.src-app-planning-components-plan-action-panel-___styles__buttons___gUBYi>div>div>div:nth-child(2){max-width:850px !important}}", "",{"version":3,"sources":["webpack://./src/app/planning/components/plan-action-panel/styles.sass"],"names":[],"mappings":"AAGI,yBADJ,kFAEQ,gBAAA,CAAA,CAER,0BACI,mGACI,0BAAA,CAAA","sourcesContent":["@import '../../../../styles/variables'\n\n.secondaryButton\n    @media (min-width: $screen-sm)\n        padding-left: 4px\n    \n@media (min-width: $screen-xxl)\n    .buttons > div > div > div:nth-child(2)\n        max-width: 850px !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"secondaryButton": "src-app-planning-components-plan-action-panel-___styles__secondaryButton___Ofw45",
	"buttons": "src-app-planning-components-plan-action-panel-___styles__buttons___gUBYi"
};
export default ___CSS_LOADER_EXPORT___;
