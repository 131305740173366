import {getAuthData} from 'cmsch-fe-library';
import React, {useEffect, FC, memo} from 'react';
import {useDispatch} from 'react-redux';

import {useOurTranslation} from 'app/translations';
import {Loader} from 'common/layout/components/loader';

import {userAction} from '../model/action';

const LogoutBase: FC = _ => {
    const {t} = useOurTranslation('user/login');
    const dispatch = useDispatch();

    const authDataSet = getAuthData().nonEmpty;

    useEffect(() => {
        if (authDataSet) {
            dispatch(userAction.logOutUser());
        }
    }, [dispatch, authDataSet]);

    return (
        <Loader
            show
            noBackground
            text={t('logingOut')}
            size="large"
            centerPosition
        />
    );
};

export const Logout = memo(LogoutBase);
