// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';
import {StringPatternSchema} from 'cmsch-fe-library';


// An ear tag of an animal without country code and codex.
// Example: "000222178"

export const shortEarTagRegexGen = (): RegExp => new RegExp("^\\w{9}$");

export const ShortEarTagSchema = StringPatternSchema<ShortEarTag>(shortEarTagRegexGen());

export type ShortEarTag = string;
