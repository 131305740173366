import {ActionSettings, IconName as TableIconName, Table, TableRowBackground, mergeObjects} from 'cmsch-fe-library';
import React, {FC, memo, ReactElement, useCallback, useMemo} from 'react';
import {Collapse} from 'react-collapse';

import {BasicAnimal} from 'api/gen/BasicAnimal';
import {StableInPlan} from 'api/gen/StableInPlan';
import {gutter} from 'app/consts';
import {useOurTranslation} from 'app/translations';
import {inflectAnimalWord} from 'app/utils/inflect-animal-word';
import {Ant} from 'common/ant';
import {Button, PopconfirmButton} from 'common/buttons';
import {IconName} from 'common/buttons/icons';
import {PageHeader} from 'common/layout';
import {LoadingItemId} from 'common/layout/types/loading-item-id';

import {BasicAnimalTableType, getColumns} from './columns';

import styles from './styles.sass';

interface Props {
    stable: StableInPlan;
    selectedAnimals: Array<number>;
    planEditable: boolean;
    visibleStables: Array<number>;
    loadingItems: Array<LoadingItemId>;

    onOpenPlanEmailModal(stableId: number): void;
    onRemoveBreeder(animalIds: Array<number>): void;
    onToggleSelectAnimal(animalId: number): void;
    onPrintBreederStablePlan(stableId: number): void;
    onToggleCollapse(id: number): void;
}

const getAnimalsIdOfOneStable = (stable: StableInPlan): Array<number> =>
    stable.animals.map(y => y.id);

interface StableButtonProps {
    stable: StableInPlan;
    text: string;
    disabled: boolean;
    confirmNeeded?: boolean;
    loading?: boolean;
    block?: boolean;
    icon?: IconName;
    testId?: string;

    onClick(stable: StableInPlan): void;
}

const StableButton = ({
    stable,
    onClick,
    text,
    disabled,
    confirmNeeded,
    loading,
    block,
    icon,
    testId,
}: StableButtonProps): ReactElement => {
    const onClickHandler = useCallback(() => onClick(stable), [onClick, stable]);
    const {t} = useOurTranslation('planning/planTable');

    if (confirmNeeded) {
        return (
            <PopconfirmButton
                onClick={onClickHandler}
                confirmTitle={t('removeFromPlanConfirmMessage')}
                okText={text}
                visuals="primary"
                loading={loading}
                disabled={disabled}
                block={block}
                icon={icon}
            >
                {text}
            </PopconfirmButton>
        );
    } else {
        return (
            <Button
                visuals="primary"
                onClick={onClickHandler}
                loading={loading}
                disabled={disabled}
                block={block}
                icon={icon}
                testId={testId}
            >
                {text}
            </Button>
        );
    }
};

// eslint-disable-next-line max-lines-per-function
const PlanAnimalsTableBase: FC<Props> = props => {
    const {
        stable,
        selectedAnimals,
        planEditable,
        visibleStables,
        loadingItems,
        onRemoveBreeder,
        onToggleSelectAnimal,
        onOpenPlanEmailModal,
        onPrintBreederStablePlan,
        onToggleCollapse,
    } = props;

    const {t, tCommon} = useOurTranslation('planning/planTable');

    const data = useMemo(() =>
        stable.animals.map((animal: BasicAnimal): BasicAnimalTableType =>
            mergeObjects(animal, {
                joinedCalveDays: `${animal.firstCalveMonths ?? 0} / ${animal.firstCalveDays ?? 0}`,
            }))
    , [stable]);

    const calcRowBackgroundDesign = useCallback(
        (row: BasicAnimalTableType, _index: number): TableRowBackground | undefined =>
            selectedAnimals.includes(row.id) ? 'success' : undefined
        , [selectedAnimals]);

    const actionSettings: ActionSettings<BasicAnimalTableType> = useMemo(() => ({
        extraActions: [
            {
                id: 'toggleSelect',
                icon: (_rowId: string | number, row: BasicAnimalTableType): TableIconName =>
                    selectedAnimals.includes(row.id) ? 'minusCircleOutlined' : 'plusCircleOutlined',
                title: (_rowId: string | number, row: BasicAnimalTableType): string =>
                    tCommon(selectedAnimals.includes(row.id) ? 'deselectItem' : 'selectItem'),
                callback: (_rowId: string | number, row: BasicAnimalTableType): void => onToggleSelectAnimal(row.id),
                disabled: !planEditable,
                testId: 'select-button',
            },
        ],
    }), [onToggleSelectAnimal, planEditable, selectedAnimals, tCommon]);

    const openPlanEmailModal = useCallback((): void => {
        onOpenPlanEmailModal(stable.id);
    }, [onOpenPlanEmailModal, stable.id]);

    const printBreederStablePlan = useCallback((): void => {
        onPrintBreederStablePlan(stable.id);
    }, [onPrintBreederStablePlan, stable.id]);

    const handleRemoveStable = useCallback((): void => {
        onRemoveBreeder(getAnimalsIdOfOneStable(stable));
    }, [onRemoveBreeder, stable]);

    const handleToggleCollapse = useCallback(() => {
        onToggleCollapse(stable.id);
    }, [stable.id, onToggleCollapse]);

    const emailSending = loadingItems.includes('sendPlanStableEmail')
        || loadingItems.includes('sendPlanBreederEmail');

    return (
        <div data-test-id="stable">
            <PageHeader
                title={t('title', {
                    name: stable.name,
                    count: stable.animals.length,
                    animalsInflectWord: inflectAnimalWord(stable.animals.length),
                })}
                extraFullWidthOnPhone
                extra={(
                    <div className={styles.actionsRow}>
                        <Ant.Row
                            gutter={[gutter, gutter]}
                            justify="end"
                        >
                            <Ant.Col
                                xs={24}
                                sm={12}
                                md={6}
                            >
                                <StableButton
                                    stable={stable}
                                    onClick={openPlanEmailModal}
                                    text={t('sendStable')}
                                    loading={loadingItems.includes('sendPlanStableEmail')}
                                    disabled={emailSending}
                                    block
                                    icon="mailOutlined"
                                    testId="send-stable-button"
                                />
                            </Ant.Col>
                            <Ant.Col
                                xs={24}
                                sm={12}
                                md={6}
                            >
                                <StableButton
                                    stable={stable}
                                    onClick={printBreederStablePlan}
                                    text={t('printStable')}
                                    loading={loadingItems.includes('printStable')}
                                    disabled={emailSending}
                                    block
                                    icon="printerOutlined"
                                    testId="print-stable-button"
                                />
                            </Ant.Col>
                            <Ant.Col
                                xs={24}
                                sm={12}
                                md={6}
                            >
                                <Button
                                    onClick={handleToggleCollapse}
                                    visuals="primary"
                                    block
                                    icon={visibleStables.includes(stable.id) ? 'upOutlined' : 'downOutlined'}
                                    testId="collapse-button"
                                >
                                    {visibleStables.includes(stable.id) ? t('collapseStable') : t('uncollapseStable')}
                                </Button>
                            </Ant.Col>
                            <Ant.Col
                                xs={24}
                                sm={12}
                                md={6}
                            >
                                <StableButton
                                    stable={stable}
                                    onClick={handleRemoveStable}
                                    text={t('removeFromPlan')}
                                    disabled={emailSending}
                                    confirmNeeded
                                    block
                                    icon="closeOutlined"
                                    testId="remove-from-plan-button"
                                />
                            </Ant.Col>
                        </Ant.Row>
                    </div>
                )}
            />
            <Collapse isOpened={visibleStables.includes(stable.id)}>
                <Table
                    tableId="planAnimals"
                    columns={getColumns(tCommon)}
                    rows={data}
                    emptyTableMessages={{noRows: t('noAnimals')}}
                    calcRowBackgroundDesign={calcRowBackgroundDesign}
                    actionSettings={actionSettings}
                    rowActionsOnRight
                    scroll={{x: true}}
                />
            </Collapse>
        </div>
    );
};

export const PlanAnimalsTable = memo(PlanAnimalsTableBase);
