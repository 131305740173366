// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


const requiredPart = t.interface({
  empty: t.union([t.boolean, t.null]),
  sorted: t.union([t.boolean, t.null]),
  unsorted: t.union([t.boolean, t.null]),
});

export const SortSchema = excess(requiredPart);

export interface Sort extends t.TypeOf<typeof SortSchema> {}
