// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractTableFilter, AbstractTableFilterSchema } from 'api/gen/AbstractTableFilter';

const requiredPart = t.interface({
  tag: t.literal('BETWEEN_INTEGERS'),
  field: t.string,
  from: t.Integer,
  to: t.Integer,
});

export const BetweenIntegersFilterSchema = excess(t.intersection([AbstractTableFilterSchema.type, requiredPart]));

export interface BetweenIntegersFilter extends t.TypeOf<typeof BetweenIntegersFilterSchema> {}
