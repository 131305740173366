// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// Example: FLECKVIEH

export const TenantSchema = t.keyof({
  FLECKVIEH: null,
  HOLSTEIN: null,
});

export type Tenant = t.TypeOf<typeof TenantSchema>;
