import {TableDataRequest} from 'cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import React, {FC, memo, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {BreederSuite} from 'api/gen/BreederSuite';
import {breederAction} from 'app/breeder/model';
import {alternativeEmailsSelector} from 'app/breeder/model/selector';
import {useOurTranslation} from 'app/translations';
import {simpleUserSelector} from 'app/user';
import {Content, layoutAction, PageHeader, simpleLayoutSelector} from 'common/layout';
import {formHelpers} from 'utils/forms';
import {replacePlaceholderInTemplate} from 'utils/replace-text-editor-placeholder';

import {BreederSuitesTable, SendToBreedersModal} from '../components';
import {BreederSuiteRow} from '../components/breeder-suites-table/columns';
import {SendToBreedersFormValues, sendToBreedersFormName} from '../components/send-to-breeders-form/form-values';
import {breederSuiteAction, selectedBreederSuitesEmailsOptionsSelector} from '../model';
import {simpleBreederSuiteSelector} from '../model/selector';

const ListBase: FC = () => {
    const currentUser = useSelector(simpleUserSelector.currentUser);
    const selectedBreederSuitesIds = useSelector(simpleBreederSuiteSelector.selectedBreederSuitesIds);
    const selectedBreederSuites = useSelector(simpleBreederSuiteSelector.selectedBreederSuites);
    const emailOptions = useSelector(selectedBreederSuitesEmailsOptionsSelector);
    const breederSuitesExporting = useSelector(simpleLayoutSelector.isItemLoading('exportBreederSuites'));
    const mailSending = useSelector(simpleLayoutSelector.isItemLoading('sendMailToBreeders'));
    const content = useSelector(simpleBreederSuiteSelector.emailTemplate);
    const alternativeEmails = useSelector(alternativeEmailsSelector);
    const sendToBreedersInitialsValues: SendToBreedersFormValues = useMemo(() => ({
        emails: emailOptions.map(option => option.value),
        content: replacePlaceholderInTemplate(content),
        emailsToSendCopyTo: alternativeEmails,
        mergeStables: true,
    }), [alternativeEmails, content, emailOptions]);

    const {t} = useOurTranslation('breederSuite/list');
    const dispatch = useDispatch();

    const getBreederSuites = useCallback((tableDataRequest: Opt<TableDataRequest<BreederSuite>>) => {
        dispatch(breederSuiteAction.getBreederSuites(tableDataRequest));
    }, [dispatch]);

    const toggleSelect = useCallback((
        ids: Array<string>,
        rows: Array<BreederSuiteRow>,
    ) => {
        dispatch(breederSuiteAction.toggleSelect(ids, rows));
    }, [dispatch]);

    const exportBreederSuites = useCallback((ids: Array<string>) => {
        dispatch(breederSuiteAction.exportBreederSuites(ids));
    }, [dispatch]);

    const sendMailToBreeders = useCallback((data: SendToBreedersFormValues) => {
        dispatch(breederSuiteAction.sendMailToBreeders(data));
    }, [dispatch]);

    const openSendToBreedersModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('sendToBreeders', true));
    }, [dispatch]);

    const closeSendToBreedersModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('sendToBreeders', false));
        dispatch(formHelpers.reset('sendToBreeders'));
    }, [dispatch]);

    const getStableAlternativeEmails = useCallback(() => {
        const ids = selectedBreederSuites.map(x => x.stableId);

        if (!isEmpty(ids)) dispatch(breederAction.getStablesAlternativeEmails(ids));
    }, [dispatch, selectedBreederSuites]);

    const setSendToBreedersFormValues = useCallback((values: SendToBreedersFormValues) => {
        dispatch(formHelpers.initialize(sendToBreedersFormName, values));
    }, [dispatch]);

    useEffect(() => {
        dispatch(breederSuiteAction.getEmailTemplate());
    }, [dispatch]);

    return (
        <Content>
            <PageHeader title={t('breederSuites')} />

            <BreederSuitesTable
                selectedIds={selectedBreederSuitesIds}
                selectedRows={selectedBreederSuites}
                breederSuitesExporting={breederSuitesExporting}
                mailSending={mailSending}
                currentUser={currentUser}
                getBreederSuites={getBreederSuites}
                toggleSelect={toggleSelect}
                exportBreederSuites={exportBreederSuites}
                sendMailToBreeders={openSendToBreedersModal}
            />
            <SendToBreedersModal
                emailOptions={emailOptions}
                values={sendToBreedersInitialsValues}
                closeModal={closeSendToBreedersModal}
                onSubmit={sendMailToBreeders}
                getStableAlternativeEmails={getStableAlternativeEmails}
                setSendToBreedersFormValues={setSendToBreedersFormValues}
            />
        </Content>
    );
};

export const List = memo(ListBase);
