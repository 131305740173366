// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { MeasurementSearch, MeasurementSearchSchema } from 'api/gen/MeasurementSearch';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  breedingNumber: t.union([BreedingNumberSchema, t.null]),
  measurements: t.union([t.array(MeasurementSearchSchema), t.null]),
  stableName: t.union([StableNameSchema, t.null]),
});

export const AnimalMeasurementSearchSchema = excess(requiredPart);

export interface AnimalMeasurementSearch extends t.TypeOf<typeof AnimalMeasurementSearchSchema> {}
