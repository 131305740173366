// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';
import {StringPatternSchema} from 'cmsch-fe-library';


// A vat number of a company.
// Example: "CZ26162539"

export const vatNumberRegexGen = (): RegExp => new RegExp("^(CZ|cz)\\d{8,10}$");

export const VatNumberSchema = StringPatternSchema<VatNumber>(vatNumberRegexGen());

export type VatNumber = string;
