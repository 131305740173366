// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(min-width: 768px){.src-app-repair-components-animal-info-___styles__border___oYou1{border-left:1px solid #e5e5e5}}", "",{"version":3,"sources":["webpack://./src/app/repair/components/animal-info/styles.sass"],"names":[],"mappings":"AAIA,yBACI,iEACI,6BAAA,CAAA","sourcesContent":["@import '../../../../styles/variables'\n\n$border-color: #e5e5e5\n\n@media (min-width: $screen-md)\n    .border\n        border-left: 1px solid $border-color"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border": "src-app-repair-components-animal-info-___styles__border___oYou1"
};
export default ___CSS_LOADER_EXPORT___;
