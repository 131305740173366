// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


const requiredPart = t.interface({
  token: t.union([t.string, t.null]),
});

export const JwtDetailsSchema = excess(requiredPart);

export interface JwtDetails extends t.TypeOf<typeof JwtDetailsSchema> {}
