import {putAll, showBeError, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {breederAction, GetStableBreedingsAction} from '../action';

function* execute({payload: {stableId}}: GetStableBreedingsAction): SagaIterator {
    if (stableId) {
        const response = yield* call(Api.findBreedingsByStable, {stableId});

        if (!response.isSuccess) {
            yield putAll(showBeError(response, t('breeder')('breedingsOverview')));
        } else {
            yield* put(breederAction.getStableBreedingsSuccess(response.data));
        }
    }
}

export function* getStableBreedingsSaga(): SagaIterator {
    yield takeLatestF('breeder/GET_STABLE_BREEDINGS', execute);
}
