import {usePrevious} from 'cmsch-fe-library';
import {isEqual} from 'lodash/fp';
import React, {FC, memo, useEffect} from 'react';

import {useOurTranslation} from 'app/translations';
import {FooterButtons} from 'common/buttons';
import {useForm} from 'utils/forms';

import {EditRatingFormValues, editRatingInitialValues, editRatingFormName} from '../../types/edit-rating-values';
import {validate} from './validate';

interface Props {
    initialValues: EditRatingFormValues;
    onSubmit(formValues: EditRatingFormValues): void;
    onCancel(): void;
    setRatingFormValues(rating: EditRatingFormValues): void;
}

const EditRatingFormBase: FC<Props> = props => {
    const {initialValues, onSubmit, onCancel, setRatingFormValues} = props;

    const {t} = useOurTranslation('common');

    const previousInitialValues = usePrevious(initialValues);

    useEffect(() => {
        if (!isEqual(initialValues, previousInitialValues)) {
            setRatingFormValues(initialValues);
        }
    }, [initialValues, setRatingFormValues]); // eslint-disable-line react-hooks/exhaustive-deps

    const {Fields, Form, submitting} = useForm({
        form: editRatingFormName,
        initialValues: editRatingInitialValues,
        validate,
        onSubmit,
    });

    return (
        <Form>
            <Fields.NumberInput
                name="lactation"
                label={t('lactation')}
                disabled={submitting}
                isRequired
            />
            <Fields.DateInput
                name="ratingDate"
                label={t('ratingDate')}
                disabled={submitting}
                clearable
                isRequired
            />
            <div className="mt-3">
                <FooterButtons
                    onCancel={onCancel}
                    submitting={submitting}
                />
            </div>
        </Form>
    );
};

export const EditRatingForm = memo(EditRatingFormBase);
