import {buildAuthToken, Requester, ResponseError} from 'cmsch-fe-library';
import {Store} from 'redux';

import {Config} from './config';
import {logger} from './sentry-logger';
import {State} from './setup/state';
import {userAction} from './user/model/action';

let store: Store<State>;
export const injectStore = (_store: Store<State>): void => {
    store = _store;
};

const reportValidationError = (error: Error): void => {
    logger.logError(error);
};

const onServerError = (error: ResponseError): void => {
    logger.logErrorAndConsole(error);
    store.dispatch(userAction.setIsServerError(true));
};

const getAuthToken = (): string | undefined => buildAuthToken(Config.jwtAuth ? 'jwt' : 'basic');

// eslint-disable-next-line import/no-unused-modules
export const apiRequester = new Requester({
    baseUrl: Config.apiUrl,
    devMode: Config.isDev,
    withCredentials: Config.isDev,
    timeout: 60000,
    onValidationError: reportValidationError,
    onServerError,
    getAuthToken,
});
