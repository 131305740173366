import {putAll, showBeError, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {GetApprovedMeasurementAction, repairAction} from '../action';

function* execute(action: GetApprovedMeasurementAction): SagaIterator {
    const {params} = action.payload;

    yield* put(layoutAction.setItemLoading('repair', true));

    const response = yield* call(Api.getMeasurementById, params);

    if (response.isSuccess) {
        yield* put(repairAction.getApprovedMeasurementSuccess(opt(response.data)));
        if (response.data.tag === 'FleckviehMeasurementDetail') {
            const {earTag, lactation, properties, ratingDate} = response.data;
            yield* put(formHelpers.initialize('repairFleckviehMeasurement', {
                earTag,
                lactation,
                properties,
                ratingDate,
                tag: 'FleckviehMeasurementUpdate',
            }));
        } else {
            const {earTag, lactation, properties, ratingDate} = response.data;
            yield* put(formHelpers.initialize('repairHolsteinMeasurement', {
                earTag,
                lactation,
                properties,
                ratingDate,
                tag: 'HolsteinMeasurementUpdate',
            }));
        }
    } else {
        yield putAll(showBeError(response, t('repair/messages')('getApprovedMeasurement')));
    }

    yield* put(layoutAction.setItemLoading('repair', true));
}

export function* getApprovedMeasurementSaga(): SagaIterator {
    yield takeLatestF('repair/GET_APPROVED_MEASUREMENT', execute);
}
