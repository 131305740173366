// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// Example: 0

export const positiveOrZeroLongMinimum = 0.0;

export const PositiveOrZeroLongSchema = t.refinement(t.Integer, x => x >= positiveOrZeroLongMinimum, 'PositiveOrZeroLong');

export type PositiveOrZeroLong = t.TypeOf<typeof PositiveOrZeroLongSchema>;
