import {showBeError, putAll, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {breedingAction, GetBreedingAction} from '../action';

function* execute({payload: {breedingId}}: GetBreedingAction): SagaIterator {
    const response = yield* call(Api.findBreedingById, {breedingId});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('breeding/sagas')('loadingOfCows')));
    } else {
        yield* put(breedingAction.getBreedingResult(response.data));
    }
}

export function* getBreedingSaga(): SagaIterator {
    yield takeLatestF('breeding/GET_BREEDING', execute);
}
