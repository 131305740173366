// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederName, BreederNameSchema } from 'api/gen/BreederName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { EarTagWithoutCountry, EarTagWithoutCountrySchema } from 'api/gen/EarTagWithoutCountry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  breederName: BreederNameSchema,
  breedingNumber: BreedingNumberSchema,
  earTag: EarTagWithoutCountrySchema,
  stableName: StableNameSchema,
});

export const AnimalWithBreedingSearchSchema = excess(requiredPart);

export interface AnimalWithBreedingSearch extends t.TypeOf<typeof AnimalWithBreedingSearchSchema> {}
