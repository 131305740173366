// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-app-___styles__menuAppWrapperLayout___vGelw{position:relative;min-height:100% !important}.src-app-___styles__menuAppWrapperSiderActive___Zx7A_{position:absolute !important;z-index:2;top:0;bottom:0}.src-app-___styles__menuAppWrapperSiderModalBackdrop___OZi5w{position:fixed;z-index:1;width:100vw;height:100vh}.src-app-___styles__errorFallbackScreen___oO3Ln{display:flex;align-items:center;justify-content:center;height:100%}.src-app-___styles__errorFallbackScreen___oO3Ln .src-app-___styles__errorFallbackScreenCard___tLP7K{width:450px;text-align:center}", "",{"version":3,"sources":["webpack://./src/app/styles.sass"],"names":[],"mappings":"AAIA,iDACI,iBAAA,CACA,0BAAA,CAEJ,sDACI,4BAAA,CACA,SAAA,CACA,KAAA,CACA,QAAA,CAEJ,6DACI,cAAA,CACA,SAAA,CACA,WAAA,CACA,YAAA,CAGJ,gDACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAEA,oGACI,WAAA,CACA,iBAAA","sourcesContent":["@import '../styles/variables'\n\n// MenuAppWrapper\n\n.menuAppWrapperLayout\n    position: relative\n    min-height: 100% !important\n\n.menuAppWrapperSiderActive\n    position: absolute !important\n    z-index: 2\n    top: 0\n    bottom: 0\n\n.menuAppWrapperSiderModalBackdrop\n    position: fixed\n    z-index: 1\n    width: 100vw\n    height: 100vh\n\n// React Error Fallback Screen\n.errorFallbackScreen\n    display: flex\n    align-items: center\n    justify-content: center\n    height: 100%\n\n    .errorFallbackScreenCard\n        width: 450px\n        text-align: center\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuAppWrapperLayout": "src-app-___styles__menuAppWrapperLayout___vGelw",
	"menuAppWrapperSiderActive": "src-app-___styles__menuAppWrapperSiderActive___Zx7A_",
	"menuAppWrapperSiderModalBackdrop": "src-app-___styles__menuAppWrapperSiderModalBackdrop___OZi5w",
	"errorFallbackScreen": "src-app-___styles__errorFallbackScreen___oO3Ln",
	"errorFallbackScreenCard": "src-app-___styles__errorFallbackScreenCard___tLP7K"
};
export default ___CSS_LOADER_EXPORT___;
