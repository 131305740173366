// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { FirstName, FirstNameSchema } from 'api/gen/FirstName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LastName, LastNameSchema } from 'api/gen/LastName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Role, RoleSchema } from 'api/gen/Role';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Tenant, TenantSchema } from 'api/gen/Tenant';

const requiredPart = t.interface({
  firstName: FirstNameSchema,
  id: PositiveLongSchema,
  lastName: LastNameSchema,
  role: RoleSchema,
  tenant: TenantSchema,
});

export const UserDetailsSchema = excess(requiredPart);

export interface UserDetails extends t.TypeOf<typeof UserDetailsSchema> {}
