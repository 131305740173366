import {putAll, showBeError, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {
    convertTableDataParamsToDto,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {GetBreedingsAction, breedingAction} from '../action';

function* execute({payload: {tableDataRequest}}: GetBreedingsAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        return yield* put(breedingAction.getBreedingsSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('breeding'));

    const tableState = yield* select(simpleTableSelector.tableState('breeding'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'breeding',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    },
    );

    const {filters, params} = convertTableDataParamsToDto({
        tableDataParams,
        fulltextFilterFields: ['districtName', 'breederName', 'stableName', 'breedingNumber'],
    });

    const response = yield* call(Api.findAllBreedingForPlanning, filters, params);

    if (response.isSuccess) {
        yield* put(breedingAction.getBreedingsSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('breeding', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('breeding/list')('planning')));
    }
}

export function* getBreedingsSaga(): SagaIterator {
    yield takeLatestF('breeding/GET_BREEDINGS', execute);
}
