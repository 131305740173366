// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';
import {StringPatternSchema} from 'cmsch-fe-library';


// A bull's line-registry.
// Example: "RAD-497"

export const lineRegistryRegexGen = (): RegExp => new RegExp("^([A-Z]{1,3}|[0-9]{1,3})-\\d{3}$");

export const LineRegistrySchema = StringPatternSchema<LineRegistry>(lineRegistryRegexGen());

export type LineRegistry = string;
