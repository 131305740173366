/* eslint-disable max-lines */
import {AnyTableColumn} from 'cmsch-fe-library';

import {HolsteinMeasurementView} from 'api/gen/HolsteinMeasurementView';
import {formatHolsteinResult} from 'app/database-of-measured/utils/format-result';
import {TFunction} from 'app/translations';

/* eslint-disable max-lines-per-function */
export const getHolsteinColumns = (tCommon: TFunction<'common'>): Array<AnyTableColumn<HolsteinMeasurementView>> => [
    {
        column: tCommon('tableGroupingHeaders.general'),
        type: 'grouping',
        subColumns: [
            {
                field: 'countryCode',
                column: tCommon('countryCode'),
                type: 'string',
                sortable: true,
                width: 55,
                verticalName: true,
            },
            {
                field: 'earTag',
                column: tCommon('earTag'),
                type: 'string',
                sortable: true,
                width: 120,
            },
            {
                field: 'ratingDate',
                column: tCommon('ratingDate'),
                type: 'date',
                dateFormat: 'date',
                sortable: true,
                width: 98,
                verticalName: true,
            },
            {
                field: 'lineRegistry',
                column: tCommon('fathersLinReg'),
                tooltip: tCommon('fathersLinRegTooltip'),
                type: 'string',
                filterTypes: ['null'],
                sortable: true,
                width: 90,
            },
            {
                field: 'userCode',
                column: tCommon('userCode'),
                type: 'string',
                align: 'right',
                sortable: true,
                verticalName: true,
                width: 50,
            },
            {
                field: 'breedingNumber',
                column: tCommon('breedingNumberAbbr'),
                tooltip: tCommon('breedingNumber'),
                type: 'string',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 105,
            },
            {
                field: 'stableName',
                column: tCommon('stable'),
                type: 'string',
                filterTypes: ['null'],
                sortable: true,
                width: 300,
            },
            {
                field: 'breederName',
                column: tCommon('company'),
                type: 'string',
                filterTypes: ['null'],
                sortable: true,
                width: 300,
            },
            {
                field: 'animalMarked',
                column: tCommon('animalMarked'),
                type: 'optionalBoolean',
                sortable: true,
                width: 60,
            },
            {
                field: 'breedingInArealRating',
                column: tCommon('breedingInArealRating'),
                type: 'optionalBoolean',
                sortable: true,
                width: 60,
            },
            {
                field: 'lactation',
                column: tCommon('lactation'),
                type: 'number',
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.exterierRating'),
        type: 'grouping',
        subColumns: [
            {
                field: 'height',
                column: tCommon('height'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'frame',
                column: tCommon('frameH'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'chestWidth',
                column: tCommon('chestWidth'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'bodyDepth',
                column: tCommon('bodyDepth'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'edginess',
                column: tCommon('edginess'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'backAngle',
                column: tCommon('backAngle'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'backWidth',
                column: tCommon('backWidth'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearPosture',
                column: tCommon('rearPosture'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'sidePosture',
                column: tCommon('sidePosture'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'hooves',
                column: tCommon('hooves'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'foreUdderAttachment',
                column: tCommon('foreUdderAttachmentAbbr'),
                tooltip: tCommon('foreUdderAttachment'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'frontTeatsPlacement',
                column: tCommon('frontTeatsPlacementAbbr'),
                tooltip: tCommon('frontTeatsPlacement'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'teatsLength',
                column: tCommon('teatsLength'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'udderDepth',
                column: tCommon('udderDepth'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearUdderHeight',
                column: tCommon('rearUdderHeightAbbrH'),
                tooltip: tCommon('rearUdderHeightH'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'suspensoryLigament',
                column: tCommon('suspensoryLigament'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearTeatsPlacement',
                column: tCommon('rearTeatsPlacementAbbr'),
                tooltip: tCommon('rearTeatsPlacement'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'udderWidth',
                column: tCommon('udderWidthH'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'bonesQuality',
                column: tCommon('bonesQuality'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'walkQuality',
                column: tCommon('walkQuality'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'fitness',
                column: tCommon('fitness'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.bodyStructure'),
        type: 'grouping',
        subColumns: [
            {
                field: 'shouldersDefect',
                column: tCommon('shouldersDefect'),
                type: 'boolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'upperLineDefect',
                column: tCommon('upperLineDefect'),
                type: 'boolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'shortSacrum',
                column: tCommon('shortSacrum'),
                type: 'boolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.extremities'),
        type: 'grouping',
        subColumns: [
            {
                field: 'extremitiesDefect',
                column: tCommon('tableGroupingHeaders.extremitiesDefects'),
                type: 'boolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'pasternDefect',
                column: tCommon('pasternDefectH'),
                type: 'boolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.udder'),
        type: 'grouping',
        subColumns: [
            {
                field: 'udderDefect',
                column: tCommon('udderDefectH'),
                type: 'boolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'teatsDefect',
                column: tCommon('teatsDefectH'),
                type: 'boolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'supernumeraryTeats',
                column: tCommon('supernumeraryTeats'),
                type: 'boolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.summary'),
        type: 'grouping',
        subColumns: [
            {
                field: 'adjustedLactationPerformance',
                column: tCommon('calculatedLactationPerformance'),
                type: 'number',
                align: 'right',
                formatValue: formatHolsteinResult('calculatedLactationPerformance'),
                sortable: true,
                width: 90,
                verticalName: true,
            },

            {
                field: 'adjustedBodyStructure',
                column: tCommon('calculatedBodyStructure'),
                type: 'number',
                formatValue: formatHolsteinResult('calculatedBodyStructure'),
                sortable: true,
                width: 90,
                verticalName: true,
            },
            {
                field: 'adjustedExtremities',
                column: tCommon('adjustedExtremities'),
                type: 'number',
                align: 'right',
                formatValue: formatHolsteinResult('calculatedExtremities'),
                sortable: true,
                width: 90,
                verticalName: true,
            },
            {
                field: 'adjustedUdder',
                column: tCommon('adjustedUdder'),
                type: 'number',
                align: 'right',
                formatValue: formatHolsteinResult('calculatedUdder'),
                sortable: true,
                width: 90,
                verticalName: true,
            },
            {
                field: 'adjustedOverallCondition',
                column: tCommon('calculatedOverallCondition'),
                type: 'number',
                align: 'right',
                formatValue: formatHolsteinResult('calculatedOverallCondition'),
                sortable: true,
                width: 90,
                verticalName: true,
            },
        ],
    },
];
