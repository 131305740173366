import {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {none, opt} from 'ts-opt';

import {RatingUpdate} from 'api/gen/RatingUpdate';
import {RatingWithUnknownBreeding} from 'api/gen/RatingWithUnknownBreeding';
import {unknownAnimalAction} from 'app/unknown-animal';
import {layoutAction} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {measurementAction} from '../model';
import {EditRatingFormValues} from '../types/edit-rating-values';

interface UseGetDataForMeasurementsList {
    approveMeasurements(): void;
    openAssignToBreedingModal(animal: RatingWithUnknownBreeding): void;
    closeAssignToBreedingModal(): void;
    assignAnimalToBreeding(): void;
    deleteMeasurement(ratingId: number): void;
    mortifyAnimal(ratingId: number): void;
    openEditRatingModal(ratingId: number): void;
    closeEditRatingModal(): void;
    assignAnimalToRating(): void;
    findEarTags(value: string): void;
    updateRating(rating: RatingUpdate): void;
    setRatingFormValues(rating: EditRatingFormValues): void;

}

// eslint-disable-next-line max-lines-per-function
export const useGetDataForMeasurementsList = (): UseGetDataForMeasurementsList => {
    const {search} = useLocation();
    const dispatch = useDispatch();

    const approveMeasurements = useCallback(() => {
        dispatch(measurementAction.approveMeasurements());
    }, [dispatch]);

    const openAssignToBreedingModal = useCallback((animal: RatingWithUnknownBreeding) => {
        dispatch(unknownAnimalAction.selectAnimalToAssign(animal));
        dispatch(layoutAction.setModalVisibility('assignToBreeding', true));
    }, [dispatch]);

    const closeAssignToBreedingModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('assignToBreeding', false));
        dispatch(formHelpers.reset('selectBreeding'));
        dispatch(unknownAnimalAction.resetAnimalToAssign());
    }, [dispatch]);

    const assignAnimalToBreeding = useCallback(() => {
        dispatch(unknownAnimalAction.assignAnimalToBreeding());
    }, [dispatch]);

    const deleteMeasurement = useCallback((ratingId: number) => {
        dispatch(measurementAction.deleteMeasurement(ratingId));
    }, [dispatch]);

    const mortifyAnimal = useCallback((ratingId: number) => {
        dispatch(measurementAction.mortifyMeasurement(ratingId));
    }, [dispatch]);

    const openEditRatingModal = useCallback((ratingId: number) => {
        dispatch(measurementAction.setRatingInEdit(ratingId));
        dispatch(layoutAction.setModalVisibility('editRating', true));
    }, [dispatch]);

    const closeEditRatingModal = useCallback(() => {
        dispatch(layoutAction.setModalVisibility('editRating', false));
        dispatch(formHelpers.reset('selectAnimal'));
        dispatch(measurementAction.setRatingInEdit());
    }, [dispatch]);

    const assignAnimalToRating = useCallback(() => {
        dispatch(measurementAction.assignRating());
    }, [dispatch]);

    const findEarTags = useCallback((value: string) => {
        dispatch(measurementAction.findEarTags(value));
    }, [dispatch]);

    const updateRating = useCallback((rating: RatingUpdate) => {
        dispatch(measurementAction.updateRating(rating));
    }, [dispatch]);

    const setRatingFormValues = useCallback((rating: EditRatingFormValues) => {
        dispatch(formHelpers.initialize('editRating', rating));
    }, [dispatch]);

    const resetEditedRatingId = useCallback(() => {
        dispatch(measurementAction.setEditedRating(none));
    }, [dispatch]);

    useEffect(() => {
        dispatch(unknownAnimalAction.getUnknownAnimals());
        dispatch(unknownAnimalAction.getStablesWithBreedings());
    }, [dispatch]);

    useEffect(() => {
        dispatch(measurementAction.getMeasurements(opt({action: 'refresh'})));
    }, [dispatch, search]);

    useEffect(() => (): void => {
        resetEditedRatingId();
        dispatch(measurementAction.getMeasurements(opt({action: 'reset'})));
    }, [dispatch, resetEditedRatingId]);

    return ({
        approveMeasurements,
        openAssignToBreedingModal,
        assignAnimalToBreeding,
        assignAnimalToRating,
        closeAssignToBreedingModal,
        closeEditRatingModal,
        deleteMeasurement,
        findEarTags,
        mortifyAnimal,
        openEditRatingModal,
        setRatingFormValues,
        updateRating,
    });
};
