import {Options} from 'cmsch-fe-library';
import React, {FC, memo, ReactNode, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt, opt} from 'ts-opt';

import {AnimalWithBreedingSearch} from 'api/gen/AnimalWithBreedingSearch';
import {MeasurementDetail} from 'api/gen/MeasurementDetail';
import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';
import {RepairMeasurementTabKey} from 'app/repair/types/repair-measurement-tab-key';
import {useOurTranslation} from 'app/translations';
import {AntTabs} from 'common/ant';
import {FooterButtons} from 'common/buttons';
import {formHelpers, useForm} from 'utils/forms';

import {RepairMeasurementShowFieldWrapper} from '../repair-measurement-show-field-wrapper';
import {ColorsFormSection} from './colors-form-section';
import {ExtremitiesDefectsFormSection} from './extremities-defects-form-section';
import {ExtremitiesFormSection} from './extremities-form-section';
import {FrameDefectsFormSection} from './frame-defects-form-section';
import {FrameFormSection} from './frame-form-section';
import {GeneralFormSection} from './general-form-section';
import {MusculatureFormSection} from './musculature-form-section';
import {OtherFormSection} from './other-form-section';
import {SummaryFormSection} from './summary-form-section';
import {UdderDefectsFormSection} from './udder-defects-form-section';
import {UdderFormSection} from './udder-form-section';
import {validate} from './validate';
import {
    RepairFleckviehMeasurementFormValues,
    repairFleckviehMeasurementInitialValues,
    repairFleckviehMeasurementForm,
} from './values';

interface Props {
    measurementDetail: MeasurementDetail;
    foundAnimalsOptions: Options<string>;
    foundAnimal: Opt<AnimalWithBreedingSearch>;
    findAnimalsInProgress: boolean;
    onSubmit(values: RepairFleckviehMeasurementFormValues): void;
    findAnimals(earTag: string): void;
}

// eslint-disable-next-line max-lines-per-function
const RepairFleckviehMeasurementFormBase: FC<Props> = props => {
    const {measurementDetail, foundAnimalsOptions, foundAnimal, findAnimalsInProgress, onSubmit, findAnimals} = props;
    const {breedingNumber, stableName, breederName} = measurementDetail;

    const {tCommon} = useOurTranslation('common');

    const {submitting, Fields, Form, pristine} = useForm({
        form: repairFleckviehMeasurementForm,
        initialValues: repairFleckviehMeasurementInitialValues,
        onSubmit,
        validate,
    });

    const [activeKey, setActiveKey] = useState<RepairMeasurementTabKey>('general');

    const valid = useSelector(formHelpers.isValid('repairFleckviehMeasurement'));

    const dispatch = useDispatch();
    const onChange = useCallback((newActiveKey: string) => {
        if (valid) {
            setActiveKey(newActiveKey as RepairMeasurementTabKey);
        } else {
            dispatch(formHelpers.submit('repairFleckviehMeasurement'));
        }
    }, [dispatch, valid]);

    const isDisabled = useCallback((key: RepairMeasurementTabKey) => !valid && activeKey !== key, [activeKey, valid]);

    const renderTabTitle = useCallback((key: RepairMeasurementTabKey) => isDisabled(key) ? (
        <span title={tCommon('formStepNotValid')}>{tCommon(`tableGroupingHeaders.${key}`)}</span>
    ) : tCommon(`tableGroupingHeaders.${key}`), [isDisabled, tCommon]);

    const PropertiesSectionField = Fields.genSection<'properties', PropertiesFormSectionProps>();

    const addTestId = (el: ReactNode, testId: string): ReactNode =>
        (<div data-test-id={testId}>{el}</div>);

    return (
        <Form>
            <AntTabs
                activeKey={activeKey}
                onChange={onChange}
            >
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('general'), 'general')}
                    key="general"
                    disabled={isDisabled('general')}
                >
                    <Fields.StringSelect
                        name="earTag"
                        label={tCommon('earTag')}
                        options={foundAnimalsOptions}
                        searchable
                        clearable
                        onSearch={findAnimals}
                        includeValueToOptions
                        isRequired
                        fieldIsLoading={findAnimalsInProgress}
                    />
                    <Fields.DateInput
                        name="ratingDate"
                        label={tCommon('ratingDate')}
                        isRequired
                        dontIncludeFuture
                    />
                    <RepairMeasurementShowFieldWrapper
                        label={tCommon('breeding')}
                        value={foundAnimal.prop('breedingNumber').alt(opt(breedingNumber))}
                    />
                    <RepairMeasurementShowFieldWrapper
                        label={tCommon('stable')}
                        value={foundAnimal.prop('stableName').alt(opt(stableName))}
                    />
                    <RepairMeasurementShowFieldWrapper
                        label={tCommon('company')}
                        value={foundAnimal.prop('breederName').alt(opt(breederName))}
                    />
                    <PropertiesSectionField
                        name="properties"
                        Component={GeneralFormSection}
                        props={{tCommon}}
                    />
                    <Fields.NumberInput
                        name="lactation"
                        label={tCommon('lactation')}
                        isRequired
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('frame'), 'frame')}
                    key="frame"
                    disabled={isDisabled('frame')}
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={FrameFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('frameDefects'), 'frameDefects')}
                    key="frameDefects"
                    disabled={isDisabled('frameDefects')}
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={FrameDefectsFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('musculature'), 'musculature')}
                    key="musculature"
                    disabled={isDisabled('musculature')}
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={MusculatureFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('extremities'), 'extremities')}
                    key="extremities"
                    disabled={isDisabled('extremities')}
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={ExtremitiesFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('extremitiesDefects'), 'extremitiesDefects')}
                    key="extremitiesDefects"
                    disabled={isDisabled('extremitiesDefects')}
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={ExtremitiesDefectsFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('udder'), 'udder')}
                    key="udder"
                    disabled={isDisabled('udder')}
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={UdderFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('udderDefects'), 'udderDefects')}
                    key="udderDefects"
                    disabled={isDisabled('udderDefects')}
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={UdderDefectsFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('colors'), 'colors')}
                    key="colors"
                    disabled={isDisabled('colors')}
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={ColorsFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('summary'), 'summary')}
                    key="summary"
                    disabled={isDisabled('summary')}
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={SummaryFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={addTestId(renderTabTitle('other'), 'other')}
                    key="other"
                    disabled={isDisabled('other')}
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={OtherFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
            </AntTabs>
            <FooterButtons
                withoutCancel
                disabledSubmit={submitting || pristine}
            />
        </Form>
    );
};

export const RepairFleckviehMeasurementForm = memo(RepairFleckviehMeasurementFormBase);
