// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';

const requiredPart = t.interface({
  lactation: PositiveIntegerSchema,
  ratingDate: DateSchema,
});

export const RatingUpdateSchema = excess(requiredPart);

export interface RatingUpdate extends t.TypeOf<typeof RatingUpdateSchema> {}
