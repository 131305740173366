// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-app-admin-components-app-info-row-___styles__versionColumn___mtk7l{display:grid !important;align-content:end}.src-app-admin-components-app-info-row-___styles__appInfo___k55HZ{display:grid !important;grid-template-columns:fit-content(100px) 1fr}", "",{"version":3,"sources":["webpack://./src/app/admin/components/app-info-row/styles.sass"],"names":[],"mappings":"AAAA,wEACI,uBAAA,CACA,iBAAA,CAEJ,kEACI,uBAAA,CACA,4CAAA","sourcesContent":[".versionColumn\n    display: grid !important\n    align-content: end\n\n.appInfo\n    display: grid !important\n    grid-template-columns: fit-content(100px) 1fr\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"versionColumn": "src-app-admin-components-app-info-row-___styles__versionColumn___mtk7l",
	"appInfo": "src-app-admin-components-app-info-row-___styles__appInfo___k55HZ"
};
export default ___CSS_LOADER_EXPORT___;
