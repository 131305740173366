import {TableColumn} from 'cmsch-fe-library';

import {BasicAnimal} from 'api/gen/BasicAnimal';
import {TFunction} from 'app/translations';

export interface BasicAnimalTableType extends BasicAnimal {
    joinedCalveDays: string;
}

// eslint-disable-next-line max-lines-per-function
export const getColumns = (t: TFunction<'common'>): Array<TableColumn<BasicAnimalTableType>> => [
    {
        field: 'stableName',
        column: t('stable'),
        type: 'string',
        width: 200,
        disableFilter: true,
    },
    {
        field: 'sampleIdWithoutCodex',
        column: t('earTag'),
        type: 'string',
        width: 130,
        disableFilter: true,
    },
    {
        field: 'codex',
        column: t('codex'),
        type: 'string',
        width: 80,
        disableFilter: true,
    },
    {
        field: 'breed',
        column: t('breed'),
        type: 'string',
        width: 95,
        disableFilter: true,
    },
    {
        field: 'fathersLinReg',
        column: t('fathersLinReg'),
        type: 'string',
        width: 95,
        disableFilter: true,
    },
    {
        field: 'fathersName',
        column: t('fathersName'),
        type: 'string',
        width: 150,
        disableFilter: true,
    },
    {
        field: 'marked',
        column: t('marked'),
        type: 'boolean',
        formatValue: (value: boolean): string => value ? 'T' : 'X',
        width: 95,
        disableFilter: true,
    },
    {
        field: 'daysSinceCalveDate',
        column: t('daysSinceCalveDateAbbr'),
        tooltip: t('daysSinceCalveDate'),
        type: 'number',
        width: 110,
        disableFilter: true,
    },
    {
        field: 'joinedCalveDays',
        column: t('joinedCalveDaysAbbr'),
        tooltip: t('joinedCalveDays'),
        type: 'string',
        width: 95,
        disableFilter: true,
    },
    {
        field: 'calveDate',
        column: t('calveDateAbbr'),
        tooltip: t('calveDate'),
        type: 'date',
        dateFormat: 'date',
        width: 140,
        disableFilter: true,
    },
    {
        field: 'proficiencyTest',
        column: t('proficiencyTestAbbr'),
        tooltip: t('proficiencyTest'),
        type: 'number',
        width: 50,
        disableFilter: true,
    },
];
