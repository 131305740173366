import {showBeError, putAll, takeEveryF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {GetImportsAction, importKkutAction} from '../action';

function* execute(_: GetImportsAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('getImports', true));
    yield* put(importKkutAction.resetImports());

    const response = yield* call(Api.findAllKkutFiles);

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('importKkut/messages')('errorMessage')));
    } else {
        yield* put(importKkutAction.getImportsSuccess(response.data));
    }

    yield* put(layoutAction.setItemLoading('getImports', false));
}

export function* getImportsSaga(): SagaIterator {
    yield takeEveryF('importKkut/GET_IMPORTS', execute);
}
