import {Options, TableColumn} from 'cmsch-fe-library';

import {EmailAudit} from 'api/gen/EmailAudit';
import {EmailTemplateType} from 'api/gen/EmailTemplateType';
import {TFunction} from 'app/translations';

export const getColumns = (
    typeOptions: Options<EmailTemplateType>,
    t: TFunction<'sentEmailsTable'>,
): Array<TableColumn<EmailAudit>> => [
    {
        field: 'created',
        column: t('created'),
        type: 'date',
        dateFormat: 'datetime',
        sortable: true,
        width: 170,
    },
    {
        field: 'type',
        type: 'option',
        column: t('emailType'),
        filterTypes: ['multiSelectString'],
        options: typeOptions,
        sortable: true,
        width: 180,

    },
    {
        field: 'emailList',
        column: t('emailList'),
        type: 'string',
        filterTypes: ['null'],
        sortable: true,
        flexibleWidth: true,
    },
];
