import {showBeError, putAll, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';
import {
    convertTableDataParamsToDto,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {bullAction, GetPlannigDaughtersAction} from '../action';

function* execute({payload: {tableDataRequest, bullId}}: GetPlannigDaughtersAction): SagaIterator {
    yield* put(tablesAction.tableDataFetchStart('daughters'));
    const tableState = yield* select(simpleTableSelector.tableState('daughters'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'daughters',
        tableDataRequest: tableDataRequest.orElse({action: 'reset'}),
        tableState,
    });

    const {filters, params} = convertTableDataParamsToDto({
        tableDataParams,
        fulltextFilterFields: ['shortEarTag', 'stableName', 'breederName'],
    });
    const response = yield* call(Api.findAllDaughters, filters, params, {bullId});

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('bull/sagas')('getBullAnimals')));
    } else {
        yield* put(tablesAction.tableDataFetchSuccess('daughters', tableDataParams));
        yield* put(bullAction.getPlannigDaughtersSuccess(response.data));
    }

    yield* put(layoutAction.setItemLoading('getDaughters', false));
}

export function* getBullAnimalsSaga(): SagaIterator {
    yield takeLatestF('bull/GET_BULL_ANIMALS', execute);
}
