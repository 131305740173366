import {AppInfo} from 'api/gen/AppInfo';

export const adminAction = {
    getAppInfo: () => ({
        type: 'admin/GET_APP_INFO',
    }) as const,
    getAppInfoSuccess: (appInfo: AppInfo) => ({
        type: 'admin/GET_APP_INFO_SUCCESS',
        payload: {appInfo},
    }) as const,
};

export type GetAppInfoAction = ReturnType<typeof adminAction.getAppInfo>;
export type GetAppInfoSuccessAction = ReturnType<typeof adminAction.getAppInfoSuccess>;

export type AdminAction =
    | GetAppInfoAction
    | GetAppInfoSuccessAction
;
