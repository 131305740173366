// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// The password of a user.
// Example: "JohnDoe01"

export const passwordMinLength = 8;
export const passwordMaxLength = 255;

export const PasswordSchema = t.refinement(t.string, n => n.length >= passwordMinLength && n.length <= passwordMaxLength, 'Password');

export type Password = string;
