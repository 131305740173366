// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// The unknown date imported from the Access DB.
// Example: "636537675794200000"

export const unknownDateMinLength = 1;
export const unknownDateMaxLength = 255;

export const UnknownDateSchema = t.refinement(t.string, n => n.length >= unknownDateMinLength && n.length <= unknownDateMaxLength, 'UnknownDate');

export type UnknownDate = string;
