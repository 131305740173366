// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// Example: P

export const RatingTypeSchema = t.keyof({
  P: null,
  A: null,
});

export type RatingType = t.TypeOf<typeof RatingTypeSchema>;
