import {TypedFormSectionInjectedProps} from 'cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairHolsteinMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairHolsteinMeasurementFormValues, 'properties'>;

const UdderFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="udderDepth"
                label={tCommon('udderDepth')}
                isRequired
            />
            <Fields.NumberInput
                name="rearUdderHeight"
                label={tCommon('rearUdderHeight')}
                isRequired
            />
            <Fields.NumberInput
                name="frontTeatsPlacement"
                label={tCommon('frontTeatsPlacement')}
                isRequired
            />
            <Fields.NumberInput
                name="rearTeatsPlacement"
                label={tCommon('rearTeatsPlacement')}
                isRequired
            />
            <Fields.NumberInput
                name="suspensoryLigament"
                label={tCommon('suspensoryLigament')}
                isRequired
            />
            <Fields.NumberInput
                name="foreUdderAttachment"
                label={tCommon('foreUdderAttachment')}
                isRequired
            />
            <Fields.NumberInput
                name="teatsLength"
                label={tCommon('teatsLength')}
                isRequired
            />
            <Fields.NumberInput
                name="udderWidth"
                label={tCommon('udderWidth')}
                isRequired
            />
        </Fragment>
    );
};

export const UdderFormSection = memo(UdderFormSectionBase);
