// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// The name of a district.
// Example: "KLATOVY"

export const districtNameMinLength = 1;
export const districtNameMaxLength = 255;

export const DistrictNameSchema = t.refinement(t.string, n => n.length >= districtNameMinLength && n.length <= districtNameMaxLength, 'DistrictName');

export type DistrictName = string;
