import {Options} from 'cmsch-fe-library';
import React, {FC, memo, useEffect} from 'react';

import {useOurTranslation} from 'app/translations';
import {FooterButtons} from 'common/buttons';
import {useForm} from 'utils/forms';

import {SendToBreedersFormValues, sendToBreedersInitialValues, sendToBreedersFormName} from './form-values';
import {validate} from './validate';

interface Props {
    emailOptions: Options<string>;
    values: SendToBreedersFormValues;
    onSubmit(values: SendToBreedersFormValues): void;
    closeModal(): void;
    setSendToBreedersFormValues(values: SendToBreedersFormValues): void;
    getStableAlternativeEmails(): void;
}

const SendToBreedersFormBase: FC<Props> = props => {
    const {emailOptions, values, closeModal, onSubmit, setSendToBreedersFormValues, getStableAlternativeEmails} = props;

    const {t, tCommon} = useOurTranslation('breederSuites/SendToBreedersForm');

    useEffect(() => {
        setSendToBreedersFormValues(values);
    }, [values.emailsToSendCopyTo]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getStableAlternativeEmails();
    }, [getStableAlternativeEmails]);

    const {Fields, Form, submitting} = useForm({
        form: sendToBreedersFormName,
        initialValues: sendToBreedersInitialValues,
        destroyOnUnmount: true,
        onSubmit,
        validate,
    });

    return (
        <Form>
            <Fields.Tags
                name="emails"
                label={tCommon('whom')}
                options={emailOptions}
                isRequired
            />
            <Fields.Tags
                name="emailsToSendCopyTo"
                label={tCommon('copy')}
            />
            <Fields.TextEditor
                name="content"
                label={t('content')}

            />
            <Fields.Checkbox
                name="mergeStables"
                label={t('mergeStables')}

            />
            <FooterButtons
                onCancel={closeModal}
                submitting={submitting}
                submitTitle={tCommon('send')}
            />
        </Form>
    );
};

export const SendToBreedersForm = memo(SendToBreedersFormBase);
