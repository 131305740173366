import {CustomWindow} from 'cmsch-fe-library';
import {createBrowserHistory} from 'history';
import {applyMiddleware, compose, legacy_createStore, AnyAction, PreloadedState} from 'redux';
import {createReduxHistoryContext} from 'redux-first-history';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import createSagaMiddleware from 'redux-saga';

import {Config} from 'app/config';

import {createReducer, resetEnhancer} from './reducer';
import saga from './saga';
import {defaultState, State} from './state';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = (window as CustomWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const reduxHistoryContext = createReduxHistoryContext({history: createBrowserHistory()});
const {createReduxHistory, routerMiddleware, routerReducer} = reduxHistoryContext;

const middlewaresToBeApplied = [
    sagaMiddleware,
    routerMiddleware,
];
if (Config.isDev && !Config.disableImmutabilityCheck) {
    middlewaresToBeApplied.push(reduxImmutableStateInvariant());
}

const reducer = resetEnhancer(createReducer(routerReducer));

const middlewares = applyMiddleware(...middlewaresToBeApplied);
const enhancer = composeEnhancers(middlewares);
const store = legacy_createStore<State, AnyAction, unknown, unknown>(
    reducer,
    defaultState as unknown as PreloadedState<State>,
    enhancer,
);

const history = createReduxHistory(store);

sagaMiddleware.run(saga);

export {history, store};
