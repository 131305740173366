import {TableDataRequest} from 'cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import React, {FC, memo, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as routerActions from 'redux-first-history';
import {Opt} from 'ts-opt';

import {PlanningBreeding} from 'api/gen/PlanningBreeding';
import {districtAction, NoUserDistricts, simpleDistrictSelector} from 'app/district';
import {useOurTranslation} from 'app/translations';
import {Content, PageHeader, simpleLayoutSelector} from 'common/layout';

import {BreedingTable} from '../components';
import {breedingAction} from '../model';

const ListBase: FC = _ => {
    const districts = useSelector(simpleDistrictSelector.activeDistricts);
    const loadingActiveDistricts = useSelector(simpleLayoutSelector.isItemLoading('activeDistricts'));

    const {t} = useOurTranslation('breeding/list');
    const dispatch = useDispatch();

    const getBreedings = useCallback((tableDataRequest: Opt<TableDataRequest<PlanningBreeding>>) => {
        dispatch(breedingAction.getBreedings(tableDataRequest));
    }, [dispatch]);

    const goTo = useCallback((path: string) => {
        dispatch(routerActions.push(path));
    }, [dispatch]);

    const goToDistricts = useCallback((): void => {
        goTo('/districts');
    }, [goTo]);

    useEffect(() => {
        dispatch(districtAction.getActiveDistricts());
    }, [dispatch]);

    if (districts && isEmpty(districts) && !loadingActiveDistricts) {
        return <NoUserDistricts goToDistricts={goToDistricts} />;
    }

    return (
        <Content>
            <PageHeader title={t('planning')} />
            <BreedingTable
                districts={districts}
                getBreedings={getBreedings}
                onGoTo={goTo}
            />
        </Content>
    );
};

export const List = memo(ListBase);
