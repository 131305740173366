// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreederInPlan, BreederInPlanSchema } from 'api/gen/BreederInPlan';

const requiredPart = t.interface({
  breeders: t.union([t.array(BreederInPlanSchema), t.null]),
});

export const PlanSchema = excess(requiredPart);

export interface Plan extends t.TypeOf<typeof PlanSchema> {}
