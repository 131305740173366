// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractTableFilter, AbstractTableFilterSchema } from 'api/gen/AbstractTableFilter';

const requiredPart = t.interface({
  tag: t.literal('FULLTEXT'),
  fields: t.array(t.string),
  value: t.string,
});

export const FulltextFilterSchema = excess(t.intersection([AbstractTableFilterSchema.type, requiredPart]));

export interface FulltextFilter extends t.TypeOf<typeof FulltextFilterSchema> {}
