import React, {FC} from 'react';

import {StableWithBreedings} from 'api/gen/StableWithBreedings';
import {useOurTranslation} from 'app/translations';
import {Modal} from 'common/layout';

import {SelectBreedingFormValues} from '../../types/select-breeding-form-values';
import {SelectBreedingForm} from '../select-breeding-form';

interface Props {
    stables: Array<StableWithBreedings> | null;
    selectBreedingValues: SelectBreedingFormValues | null;
    findEarTagsInProgress: boolean;
    assignAnimalToBreeding(): void;
    closeModal(): void;
}

export const AssignToBreedingModal: FC<Props> = props => {
    const {stables, assignAnimalToBreeding, closeModal, selectBreedingValues, findEarTagsInProgress} = props;
    const {t} = useOurTranslation('unknownAnimals');

    return (
        <Modal
            modalId="assignToBreeding"
            title={t('assignAnimalToBreeding')}
            footer={null}
            onCancel={closeModal}
        >
            <SelectBreedingForm
                stables={stables ?? []}
                currentValues={selectBreedingValues}
                findEarTagsInProgress={findEarTagsInProgress}
                onSubmit={assignAnimalToBreeding}
                onCancel={closeModal}
            />
        </Modal>
    );
};
