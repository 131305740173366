import {TableDataRequest} from 'cmsch-fe-library';
import {isUndefined} from 'lodash/fp';
import React, {FC, memo} from 'react';
import {Opt} from 'ts-opt';

import {MeasurementAnimal} from 'api/gen/MeasurementAnimal';
import {Tenant} from 'api/gen/Tenant';
import {useOurTranslation} from 'app/translations';
import {ConnectedTable} from 'utils/tables';

import {simpleMeasurementSelector} from '../../model';
import {getFleckviehColumns} from './columns-fleckvieh';
import {getHolsteinColumns} from './columns-holstein';

interface Props {
    tenant?: Tenant;
    getRatingsOfAnimal(tableDataRequest: Opt<TableDataRequest<MeasurementAnimal>>): void;
}

const RatingsOfAnimalTableBase: FC<Props> = props => {
    const {
        tenant,
        getRatingsOfAnimal,
    } = props;

    const {tCommon} = useOurTranslation('meausurement/ratingOfAnimalTable');

    if (isUndefined(tenant)) return null;

    return tenant === 'HOLSTEIN' ? (
        <ConnectedTable
            tableId="ratingsOfHolsteinAnimal"
            columns={getHolsteinColumns(tCommon)}
            rowDataPageSelector={simpleMeasurementSelector.ratingsOfAnimalPage}
            onFetchTableData={getRatingsOfAnimal}
            withoutVerticalScrollbar
            height={4000}
        />
    ) : (
        <ConnectedTable
            tableId="ratingsOfFleckviehAnimal"
            columns={getFleckviehColumns(tCommon)}
            rowDataPageSelector={simpleMeasurementSelector.ratingsOfAnimalPage}
            onFetchTableData={getRatingsOfAnimal}
            withoutVerticalScrollbar
            height={4000}
            bordered
        />
    );
};

export const RatingsOfAnimalTable = memo(RatingsOfAnimalTableBase);
