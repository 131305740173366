// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { BullNote, BullNoteSchema } from 'api/gen/BullNote';

const requiredPart = t.interface({
  marked: t.boolean,
  name: t.union([AnimalNameSchema, t.null]),
  note: t.union([BullNoteSchema, t.null]),
});

export const UpdateBullSchema = excess(requiredPart);

export interface UpdateBull extends t.TypeOf<typeof UpdateBullSchema> {}
