import {Option, Options} from 'cmsch-fe-library';
import {noop} from 'lodash/fp';
import React, {FC, memo, ReactNode, useCallback} from 'react';

import {UserDetails} from 'api/gen/UserDetails';
import {buttonsGutter} from 'app/consts';
import {TFunction, useOurTranslation} from 'app/translations';
import {Ant} from 'common/ant';
import {Button} from 'common/buttons';
import {Panel} from 'common/layout';
import {useForm} from 'utils/forms';
import {generateDescendingMonthsOptions} from 'utils/time-form-options-generator';

import {
    exportuserMeasurementsFormName,
    exportUserMeasurementsInitialValues,
} from '../../types/export-user-measurements-form-values';
import {ExportUserMeasurementsType} from '../../types/export-user-measurements-type';
import {validate} from './validate';

const monthOptions = generateDescendingMonthsOptions();

const userOptions = (
    allUsers: Array<UserDetails> | null,
    t: TFunction<'planning/exportMeasurements'>,
): Options<number> => {
    const userToOption = (user: UserDetails): Option<number> => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
    });

    return [
        {
            label: t('allUsers'),
            value: -1,
        },
        ...(allUsers ? allUsers.map(userToOption) : []),
    ];
};

interface ExportButton {
    label: string;
    valid: boolean;
    exporting: boolean;
    testId: string;
    tooltip?: string;
    onClick(): void;
}

const renderButton = ({label, valid, exporting, testId, tooltip, onClick}: ExportButton): ReactNode => (
    <Ant.Col
        xs={24}
        md={6}
        lg={4}
        xl={4}
        xxl={3}
    >
        <Button
            onClick={onClick}
            visuals="primary"
            disabled={!valid}
            loading={exporting}
            block
            testId={testId}
            tooltip={tooltip}
        >
            {label}
        </Button>
    </Ant.Col>
);

interface Props {
    allUsers: Array<UserDetails> | null;
    exporting: boolean;
    isAdmin: boolean;

    onExportMeasurements(exportType: ExportUserMeasurementsType): void;
    openMeasuredAnimalModal(): void;
}

const ExportUserMeasurementsFormBase: FC<Props> = props => {
    const {allUsers, exporting, isAdmin, onExportMeasurements, openMeasuredAnimalModal} = props;

    const {t, tCommon} = useOurTranslation('planning/exportMeasurements');

    const exportMeasurementsTest = useCallback(() => {
        onExportMeasurements(ExportUserMeasurementsType.TEST);
    }, [onExportMeasurements]);

    const exportMeasurementsDead = useCallback(() => {
        onExportMeasurements(ExportUserMeasurementsType.DEAD);
    }, [onExportMeasurements]);

    const exportRepairs = useCallback(() => {
        onExportMeasurements(ExportUserMeasurementsType.REPAIRS);
    }, [onExportMeasurements]);

    const {Fields, Form, valid} = useForm({
        form: exportuserMeasurementsFormName,
        initialValues: exportUserMeasurementsInitialValues,
        destroyOnUnmount: true,
        validate,
        onSubmit: noop,
    });

    return (
        <Panel>
            <Form>
                <div className="row mb-3">
                    <div className="col-md">
                        <Fields.StringSelect
                            name="monthYear"
                            label={t('month')}
                            options={monthOptions}
                            disabled={exporting}
                            biggerLabel
                        />
                    </div>
                    <div className="col-md">
                        {isAdmin && <Fields.NumberSelect
                            name="userItem"
                            label={tCommon('user')}
                            options={userOptions(allUsers, t)}
                            disabled={exporting}
                            biggerLabel
                        />}
                    </div>
                </div>
            </Form>
            <Ant.Row
                justify="center"
                gutter={buttonsGutter}
            >
                {renderButton({
                    label: t('measuredAnimals'),
                    valid,
                    exporting,
                    testId: 'measuredAnimals',
                    onClick: openMeasuredAnimalModal,
                })}
                {renderButton({
                    label: t('test'),
                    valid: false,
                    exporting,
                    testId: 'test',
                    tooltip: 'Funkcionalita pro sezpívání není v aktuální verzi aplikace podporována.',
                    onClick: exportMeasurementsTest,
                })}
                {renderButton({
                    onClick: exportRepairs,
                    label: t('repairs'),
                    valid,
                    exporting,
                    testId: 'repairs',
                })}
                {renderButton({
                    onClick: exportMeasurementsDead,
                    label: t('deadAnimals'),
                    valid,
                    exporting,
                    testId: 'deadAnimals',
                })}
            </Ant.Row>
        </Panel>
    );
};

export const ExportUserMeasurementsForm = memo(ExportUserMeasurementsFormBase);
