// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { SemVer, SemVerSchema } from 'api/gen/SemVer';

const requiredPart = t.interface({
  name: t.string,
  version: SemVerSchema,
});

export const LibraryInfoSchema = excess(requiredPart);

export interface LibraryInfo extends t.TypeOf<typeof LibraryInfoSchema> {}
