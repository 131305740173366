import {TableColumn} from 'cmsch-fe-library';

import {PlanningAnimal} from 'api/gen/PlanningAnimal';
import {Tenant} from 'api/gen/Tenant';
import {TFunction} from 'app/translations';
import {getBreedOptions} from 'app/utils/get-breed-options';

// eslint-disable-next-line max-lines-per-function
export const getColumns = (
    tenant: Tenant | undefined,
    t: TFunction<'breeding/AnimalsTable'>,
    tCommon: TFunction<'common'>,
): Array<TableColumn<PlanningAnimal>> => [
    {
        field: 'shortEarTag',
        column: tCommon('earTag'),
        type: 'string',
        sortable: true,
        width: 120,
    },
    {
        field: 'codex',
        column: tCommon('codex'),
        type: 'string',
        sortable: true,
        width: 80,
    },
    {
        field: 'breed',
        column: tCommon('breed'),
        type: 'reactNode',
        filterTypes: ['greaterThanBreed', 'containsString', 'null'],
        options: getBreedOptions(t, tenant),
        sortable: true,
        width: 100,
    },
    {
        field: 'numOfDaughtersToMeasure',
        column: tCommon('numOfDaughtersToMeasureAbbr'),
        tooltip: tCommon('numOfDaughtersToMeasure'),
        type: 'number',
        sortable: true,
        width: 55,
        verticalName: true,
    },
    {
        field: 'numOfMeasuredDaughters',
        column: tCommon('numOfMeasuredDaughtersAbbr'),
        tooltip: tCommon('numOfMeasuredDaughters'),
        type: 'number',
        sortable: true,
        width: 55,
        verticalName: true,
    },
    {
        field: 'numOfMeasuredDaughtersInBreeding',
        column: t('header.numOfMeasuredDaughtersInBreeding'),
        tooltip: t('tooltip.numOfMeasuredDaughtersInBreeding'),
        type: 'number',
        sortable: true,
        width: 55,
        verticalName: true,
    },
    {
        field: 'fathersName',
        column: tCommon('fathersName'),
        type: 'string',
        filterTypes: ['null'],
        sortable: true,
        flexibleWidth: true,
    },
    {
        field: 'fathersLinReg',
        column: tCommon('fathersLinReg'),
        type: 'string',
        filterTypes: ['null'],
        sortable: true,
        flexibleWidth: true,
    },
    {
        field: 'marked',
        column: tCommon('marked'),
        type: 'boolean',
        sortable: true, // TODO REPAIR
        width: 55,
        verticalName: true,
        customLabels: {yes: t('markedOptions.T'), no: t('markedOptions.X')},
        formatValue: (value: boolean): string => value ? t('markedOptions.T') : t('markedOptions.X'),
    },
    {
        field: 'rowActions',
        type: 'rowActions',
    },
    {
        field: 'daysSinceCalveDate',
        column: tCommon('daysSinceCalveDateAbbr'),
        tooltip: tCommon('daysSinceCalveDate'),
        type: 'number',
        filterTypes: ['null'],
        sortable: true,
        width: 54,
        verticalName: true,
    },
    {
        field: 'firstCalveAge',
        column: tCommon('joinedCalveDaysAbbr'),
        tooltip: tCommon('joinedCalveDays'),
        type: 'string',
        sortable: true,
        width: 88,
        filterTypes: ['null'],
    },
    {
        field: 'calveDate',
        column: tCommon('calveDate'),
        type: 'date',
        dateFormat: 'date',
        filterTypes: ['null'],
        sortable: true,
        width: 125,
    },
    {
        field: 'proficiencyTest',
        column: tCommon('proficiencyTestAbbr'),
        tooltip: tCommon('proficiencyTest'),
        type: 'number',
        filterTypes: ['null'],
        sortable: true,
        width: 55,
    },
];
