// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-app-import-kkut-components-import-kkut-result-modal-___styles__importResultTable___Zf6hW{width:100%}.src-app-import-kkut-components-import-kkut-result-modal-___styles__importResultTable___Zf6hW tr:first-of-type td{padding-top:0}.src-app-import-kkut-components-import-kkut-result-modal-___styles__importResultTable___Zf6hW tr:not(:last-child){border-bottom:1px solid #f0f0f0}.src-app-import-kkut-components-import-kkut-result-modal-___styles__importResultTable___Zf6hW td{padding:10px 0;font-size:115%}.src-app-import-kkut-components-import-kkut-result-modal-___styles__importResultTable___Zf6hW td:nth-child(1){padding-right:40px}.src-app-import-kkut-components-import-kkut-result-modal-___styles__importResultTable___Zf6hW td:nth-child(2){text-align:right}", "",{"version":3,"sources":["webpack://./src/app/import-kkut/components/import-kkut-result-modal/styles.sass"],"names":[],"mappings":"AAEA,8FACI,UAAA,CAEA,kHACI,aAAA,CAEJ,kHACI,+BAAA,CAEJ,iGACI,cAAA,CACA,cAAA,CAEJ,8GACI,kBAAA,CAEJ,8GACI,gBAAA","sourcesContent":["@import \"../../../../styles/variables\"\n\n.importResultTable\n    width: 100%\n\n    tr:first-of-type td\n        padding-top: 0\n\n    tr:not(:last-child)\n        border-bottom: 1px solid $grey-border\n\n    td\n        padding: 10px 0\n        font-size: 115%\n\n    td:nth-child(1)\n        padding-right: 40px\n\n    td:nth-child(2)\n        text-align: right\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importResultTable": "src-app-import-kkut-components-import-kkut-result-modal-___styles__importResultTable___Zf6hW"
};
export default ___CSS_LOADER_EXPORT___;
