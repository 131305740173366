import {TypedFormSectionInjectedProps} from 'cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairFleckviehMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairFleckviehMeasurementFormValues, 'properties'>;

const SummaryFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="adjustedExtremities"
                label={tCommon('adjustedExtremities')}
                isRequired
            />
            <Fields.NumberInput
                name="adjustedMusculature"
                label={tCommon('adjustedMusculature')}
                isRequired
            />
            <Fields.NumberInput
                name="adjustedTotal"
                label={tCommon('adjustedTotal')}
                isRequired
            />
            <Fields.NumberInput
                name="adjustedUdder"
                label={tCommon('adjustedUdder')}
                isRequired
            />
            <Fields.NumberInput
                name="calculatedExtremities"
                label={tCommon('calculatedExtremities')}
                isRequired
            />
            <Fields.NumberInput
                name="calculatedFrame"
                label={tCommon('calculatedFrame')}
                isRequired
            />
            <Fields.NumberInput
                name="calculatedMusculature"
                label={tCommon('calculatedMusculature')}
                isRequired
            />
            <Fields.NumberInput
                name="calculatedTotal"
                label={tCommon('calculatedTotal')}
                isRequired
            />
            <Fields.NumberInput
                name="calculatedUdder"
                label={tCommon('calculatedUdder')}
                isRequired
            />
        </Fragment>
    );
};

export const SummaryFormSection = memo(SummaryFormSectionBase);
