import {putAll, showBeError, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {breederAction, GetStableAction} from '../action';

function* execute({payload: {stableId}}: GetStableAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('stable', true));
    const response = yield* call(Api.findStableById, {stableId});

    if (response.isSuccess) {
        yield* put(breederAction.getStableSuccess(response.data));
    } else {
        yield putAll(showBeError(response, t('breeder')('stable')));
    }

    yield* put(layoutAction.setItemLoading('stable', false));
}

export function* getStableSaga(): SagaIterator {
    yield takeLatestF('breeder/GET_STABLE', execute);
}
