// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Cin, CinSchema } from 'api/gen/Cin';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Email, EmailSchema } from 'api/gen/Email';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PhoneNumber, PhoneNumberSchema } from 'api/gen/PhoneNumber';

const requiredPart = t.interface({
  cin: t.union([CinSchema, t.null]),
  email: t.union([EmailSchema, t.null]),
  phone: t.union([PhoneNumberSchema, t.null]),
});

export const UpdateBreederSchema = excess(requiredPart);

export interface UpdateBreeder extends t.TypeOf<typeof UpdateBreederSchema> {}
