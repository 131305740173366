import {ActionSettings, Table, TableColumn} from 'cmsch-fe-library';
import {difference, isEmpty} from 'lodash/fp';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {opt} from 'ts-opt';

import {Breeding} from 'api/gen/Breeding';
import {TFunction, useOurTranslation} from 'app/translations';

interface Props {
    breedings: Array<Breeding> | null;
    updateBreedingArealRating(id: number, val: boolean): void;
}

const getColumns = (tCommon: TFunction<'common'>): Array<TableColumn<Breeding>> => [
    {
        field: 'breedingNumber',
        column: tCommon('breedingNumber'),
        type: 'string',
    },
];

const BreederStablesBreedingsTableBase: FC<Props> = props => {
    const {breedings, updateBreedingArealRating} = props;

    const {t, tCommon} = useOurTranslation('breeder');

    const onSelectedIdsChange = useCallback((newSelectedIds: Array<number>) => {
        const selectedIds = opt(breedings).orElse([]).filter(x => x.arealRating).map(x => x.id);
        const toBeSelected = difference(newSelectedIds)(selectedIds);
        const toBeUnselected = difference(selectedIds)(newSelectedIds);

        if (!isEmpty(toBeSelected)) {
            toBeSelected.forEach(id => updateBreedingArealRating(id, true));
        } else if (!isEmpty(toBeUnselected)) {
            toBeUnselected.forEach(id => updateBreedingArealRating(id, false));
        }
    }, [breedings, updateBreedingArealRating]);

    const actionSettings: ActionSettings<Breeding> = useMemo(() => ({
        selectedIds: (breedings || []).filter(x => x.arealRating).map(x => x.id),
        customHeader: t('areal'),
        customWidth: 85,
        onSelectedIdsChange,
    }), [breedings, onSelectedIdsChange, t]);

    return (
        <Table
            tableId="breederStablesBreedings"
            columns={getColumns(tCommon)}
            rows={opt(breedings).orElse([])}
            emptyTableMessages={{noRows: t('emptyStableMessage')}}
            actionSettings={actionSettings}
            rowActionsOnRight
            preserveSelectedOnUnmount
        />
    );
};

export const BreederStablesBreedingsTable = memo(BreederStablesBreedingsTableBase);
