import {TableColumn} from 'cmsch-fe-library';

import {Import} from 'api/gen/Import';
import {TFunction} from 'app/translations';

export interface ImportTableType extends Import {
    id: number;
}

// eslint-disable-next-line max-lines-per-function
export const getColumns = (t: TFunction<'importKkut/table'>): Array<TableColumn<ImportTableType>> => [
    {
        field: 'fileName',
        column: t('fileName'),
        type: 'string',
        width: 185,
        disableFilter: true,
    },
    {
        field: 'importedBy',
        column: t('importedBy'),
        type: 'string',
        width: 185,
        disableFilter: true,
    },
    {
        field: 'imported',
        column: t('imported'),
        type: 'date',
        dateFormat: 'datetime',
        width: 185,
        disableFilter: true,
    },
    {
        field: 'allRecords',
        column: t('allRecords'),
        type: 'number',
        width: 100,
        disableFilter: true,
    },
    {
        field: 'importedRecords',
        column: t('importedRecords'),
        type: 'number',
        width: 100,
        disableFilter: true,
    },
    {
        field: 'duplicateRecords',
        column: t('duplicateRecords'),
        type: 'number',
        width: 100,
        disableFilter: true,
    },
];
