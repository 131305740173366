// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  arealRating: t.boolean,
  breedingNumber: BreedingNumberSchema,
  id: PositiveLongSchema,
});

export const BreedingSchema = excess(requiredPart);

export interface Breeding extends t.TypeOf<typeof BreedingSchema> {}
