// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';
import {StringPatternSchema} from 'cmsch-fe-library';


// A company identification number.
// Example: "26162539"

export const cinRegexGen = (): RegExp => new RegExp("^\\d{8}$");

export const CinSchema = StringPatternSchema<Cin>(cinRegexGen());

export type Cin = string;
