import {TypedFormSectionInjectedProps} from 'cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairFleckviehMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairFleckviehMeasurementFormValues, 'properties'>;

const FrameFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="backHeight"
                label={tCommon('backHeight')}
                isRequired
            />
            <Fields.NumberInput
                name="withersHeight"
                label={tCommon('withersHeight')}
            />
            <Fields.NumberInput
                name="backLength"
                label={tCommon('backLength')}
                isRequired
            />
            <Fields.NumberInput
                name="backWidth"
                label={tCommon('backWidth')}
                isRequired
            />
            <Fields.NumberInput
                name="bodyDepth"
                label={tCommon('bodyDepth')}
                isRequired
            />
            <Fields.NumberInput
                name="chestCircumference"
                label={tCommon('chestCircumference')}
                isRequired
            />
            <Fields.NumberInput
                name="bodyLength"
                label={tCommon('bodyLength')}
                isRequired
            />
        </Fragment>
    );
};

export const FrameFormSection = memo(FrameFormSectionBase);
