// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Codex, CodexSchema } from 'api/gen/Codex';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CountryCode, CountryCodeSchema } from 'api/gen/CountryCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ShortEarTag, ShortEarTagSchema } from 'api/gen/ShortEarTag';

const requiredPart = t.interface({
  adjustedBodyStructure: PositiveIntegerSchema,
  adjustedExtremities: PositiveIntegerSchema,
  adjustedLactationPerformance: PositiveIntegerSchema,
  adjustedOverallCondition: PositiveIntegerSchema,
  adjustedUdder: PositiveIntegerSchema,
  animalId: t.union([PositiveLongSchema, t.null]),
  backAngle: PositiveIntegerSchema,
  backWidth: PositiveIntegerSchema,
  bodyDepth: PositiveIntegerSchema,
  bodyStructure: PositiveIntegerSchema,
  bonesQuality: PositiveIntegerSchema,
  breedingId: BreedingNumberSchema,
  chestWidth: PositiveIntegerSchema,
  codex: CodexSchema,
  countryCode: CountryCodeSchema,
  edginess: PositiveIntegerSchema,
  extremities: PositiveIntegerSchema,
  extremitiesDefect: t.boolean,
  fitness: PositiveIntegerSchema,
  foreUdderAttachment: PositiveIntegerSchema,
  frame: PositiveIntegerSchema,
  frontTeatsPlacement: PositiveIntegerSchema,
  height: PositiveIntegerSchema,
  hooves: PositiveIntegerSchema,
  isDead: t.boolean,
  lactationOrder: PositiveIntegerSchema,
  lactationPerformance: PositiveIntegerSchema,
  pasternDefect: t.boolean,
  ratingDateTime: DateTimeSchema,
  ratingTimeInSeconds: t.union([t.Integer, t.null]),
  rearPosture: PositiveIntegerSchema,
  rearTeatsPlacement: PositiveIntegerSchema,
  rearUdderHeight: PositiveIntegerSchema,
  sampleId: ShortEarTagSchema,
  shortSacrum: t.boolean,
  shouldersDefect: t.boolean,
  sidePosture: PositiveIntegerSchema,
  supernumeraryTeats: t.boolean,
  suspensoryLigament: PositiveIntegerSchema,
  teatsDefect: t.boolean,
  teatsLength: PositiveIntegerSchema,
  udder: PositiveIntegerSchema,
  udderDefect: t.boolean,
  udderDepth: PositiveIntegerSchema,
  udderWidth: PositiveIntegerSchema,
  upperLineDefect: t.boolean,
  walkQuality: PositiveOrZeroIntegerSchema,
});

export const HolsteinLegacyMeasurementSchema = excess(requiredPart);

export interface HolsteinLegacyMeasurement extends t.TypeOf<typeof HolsteinLegacyMeasurementSchema> {}
