import {TableDataRequest, useIdFromRoute, useParamFromRoute} from 'cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as routerActions from 'redux-first-history';
import {opt, Opt} from 'ts-opt';

import {EarTagWithoutCountrySchema} from 'api/gen/EarTagWithoutCountry';
import {MeasurementAnimal} from 'api/gen/MeasurementAnimal';
import {homePagePath, loginPagePath} from 'app/consts';
import {useOurTranslation} from 'app/translations';
import {pathNamesSelector, simpleUserSelector} from 'app/user';
import {Content, PageHeader} from 'common/layout/components/';

import {RatingsOfAnimalTable} from '../components/ratings-of-animal-table';
import {measurementAction} from '../model/action';

const DetailBase: FC = _ => {
    const tenant = useSelector(simpleUserSelector.tenant);
    const {previous} = useSelector(pathNamesSelector);

    const {t} = useOurTranslation('measurement/detail');
    const {t: tList} = useOurTranslation('measurement/list');
    const animalId = useIdFromRoute('animalId');
    const earTag = useParamFromRoute('earTag');
    opt(earTag).narrow(EarTagWithoutCountrySchema.is).orCrash('Invalid ear tag');
    const dispatch = useDispatch();

    const goBack = useCallback(() => {
        dispatch(routerActions.push(previous.noneIf(x => x === loginPagePath).orElse(homePagePath)));
    }, [dispatch, previous]);

    const onGetRatingsOfAnimal = useCallback((tableDataRequest: Opt<TableDataRequest<MeasurementAnimal>>) => {
        dispatch(measurementAction.getRatingsOfAnimal(tableDataRequest, animalId));
    }, [animalId, dispatch]);

    return (
        <Content>
            <PageHeader
                title={t('animalMeasurements', {earTag})}
                onBackTooltip={tList('header.dataCheck')}
                onBack={goBack}
            />

            <RatingsOfAnimalTable
                tenant={tenant}
                getRatingsOfAnimal={onGetRatingsOfAnimal}
            />
        </Content>
    );
};

export const Detail = memo(DetailBase);
