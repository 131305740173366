// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';


// The unknown flag imported from the Access DB.
// Example: "VN"

export const flagMinLength = 1;
export const flagMaxLength = 255;

export const FlagSchema = t.refinement(t.string, n => n.length >= flagMinLength && n.length <= flagMaxLength, 'Flag');

export type Flag = string;
