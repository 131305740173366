/* eslint-disable max-lines */
import {AnyTableColumn} from 'cmsch-fe-library';

import {FleckviehMeasurementView} from 'api/gen/FleckviehMeasurementView';
import {formatFleckviehResult} from 'app/database-of-measured/utils/format-result';
import {TFunction} from 'app/translations';

// eslint-disable-next-line max-lines-per-function
export const getFleckviehColumns = (
    t: TFunction<'databaseOfMeasured/DatabaseOfMeasuredTable'>,
    tCommon: TFunction<'common'>,
): Array<AnyTableColumn<FleckviehMeasurementView>> => [
    {
        column: tCommon('tableGroupingHeaders.general'),
        type: 'grouping',
        subColumns: [
            {
                field: 'countryCode',
                column: tCommon('countryCode'),
                type: 'string',
                sortable: true,
                width: 55,
                verticalName: true,
            },
            {
                field: 'earTag',
                column: tCommon('earTagAbbr'),
                tooltip: tCommon('earTag'),
                type: 'string',
                sortable: true,
                width: 120,
            },
            {
                field: 'ratingDate',
                column: tCommon('ratingDate'),
                type: 'date',
                dateFormat: 'date',
                sortable: true,
                width: 98,
                verticalName: true,
            },
            {
                field: 'lineRegistry',
                column: tCommon('fathersLinReg'),
                tooltip: tCommon('fathersLinRegTooltip'),
                type: 'string',
                filterTypes: ['null'],
                sortable: true,
                width: 90,
            },
            {
                field: 'userCode',
                column: tCommon('userCode'),
                type: 'string',
                align: 'right',
                sortable: true,
                width: 50,
                verticalName: true,
            },
            {
                field: 'breedingNumber',
                column: tCommon('breeding'),
                tooltip: tCommon('breedingNumber'),
                type: 'string',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 105,
            },
            {
                field: 'stableName',
                column: tCommon('stable'),
                type: 'string',
                filterTypes: ['null'],
                sortable: true,
                width: 300,
            },
            {
                field: 'breederName',
                column: tCommon('company'),
                type: 'string',
                filterTypes: ['null'],
                sortable: true,
                width: 300,
            },
            {
                field: 'animalMarked',
                column: tCommon('animalMarked'),
                type: 'optionalBoolean',
                sortable: true,
                width: 60,
            },
            {
                field: 'breedingInArealRating',
                column: tCommon('breedingInArealRating'),
                type: 'optionalBoolean',
                sortable: true,
                width: 60,
            },
            {
                field: 'lastMilkingAt',
                column: tCommon('lastMilkingAt'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'lactation',
                column: tCommon('lactation'),
                type: 'number',
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.frame'),
        type: 'grouping',
        subColumns: [
            {
                field: 'backHeight',
                column: tCommon('backHeight'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'withersHeight',
                column: tCommon('withersHeightAbbr'),
                tooltip: tCommon('withersHeight'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'backLength',
                column: tCommon('backLength'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'backWidth',
                column: tCommon('backWidth'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'bodyDepth',
                column: tCommon('bodyDepth'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'chestCircumference',
                column: tCommon('chestCircumference'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'bodyLength',
                column: tCommon('bodyLengthAbbr'),
                tooltip: tCommon('bodyLength'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.frameDefects'),
        type: 'grouping',
        subColumns: [
            {
                field: 'narrowChest',
                column: tCommon('narrowChest'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'weakBack',
                column: tCommon('weakBack'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'looseLoins',
                column: tCommon('looseLoins'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'roofedBack',
                column: tCommon('roofedBack'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'narrowBack',
                column: tCommon('narrowBack'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.musculature'),
        type: 'grouping',
        subColumns: [
            {
                field: 'backAngle',
                column: tCommon('backAngle'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'musculature',
                column: tCommon('musculature'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'fitness',
                column: tCommon('fitness'),
                type: 'decimal',
                fixedDecimals: 1,
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
                filterTypes: ['null'],
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.extremities'),
        type: 'grouping',
        subColumns: [
            {
                field: 'rearPosture',
                column: tCommon('rearPosture'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'pastern',
                column: tCommon('pastern'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'hoovesBackHeight',
                column: tCommon('hoovesBackHeightAbbr'),
                tooltip: tCommon('hoovesBackHeight'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'ankleJoint',
                column: tCommon('ankleJointAbbr'),
                tooltip: tCommon('ankleJoint'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.extremitiesDefects'),
        type: 'grouping',
        subColumns: [
            {
                field: 'looseShoulderBlade',
                column: tCommon('looseShoulderBlade'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'frontExtremitiesOpenPosture',
                column: tCommon('frontExtremitiesOpenPostureAbbr'),
                tooltip: tCommon('frontExtremitiesOpenPosture'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'openedHooves',
                column: tCommon('openedHoovesAbbr'),
                tooltip: tCommon('openedHooves'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'posture',
                column: tCommon('posture'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'hoovesTorsion',
                column: tCommon('hoovesTorsion'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.udder'),
        type: 'grouping',
        subColumns: [
            {
                field: 'frontUdderLength',
                column: tCommon('frontUdderLengthAbbr'),
                tooltip: tCommon('frontUdderLength'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'frontUdderAttachmentAngle',
                column: tCommon('frontUdderAttachmentAngleAbbr'),
                tooltip: tCommon('frontUdderAttachmentAngle'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearUdderLength',
                column: tCommon('rearUdderLengthAbbr'),
                tooltip: tCommon('rearUdderLength'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearUdderAttachment',
                column: tCommon('rearUdderAttachment'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'suspensoryLigament',
                column: tCommon('suspensoryLigament'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'udderDepth',
                column: tCommon('udderDepth'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'frontTeatsPlacement',
                column: tCommon('frontTeatsPlacementAbbr'),
                tooltip: tCommon('frontTeatsPlacement'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearTeatsPlacement',
                column: tCommon('rearTeatsPlacementAbbr'),
                tooltip: tCommon('rearTeatsPlacement'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'rearTeatsPosition',
                column: tCommon('rearTeatsPositionAbbr'),
                tooltip: tCommon('rearTeatsPosition'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'teatsLength',
                column: tCommon('teatsLength'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'teatsWidth',
                column: tCommon('teatsWidth'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.udderDefects'),
        type: 'grouping',
        subColumns: [
            {
                field: 'edematicUdder',
                column: tCommon('edematicUdderAbbr'),
                tooltip: tCommon('edematicUdder'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'gradualUdder',
                column: tCommon('gradualUdderAbbr'),
                tooltip: tCommon('gradualUdder'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'frontTeatsSideways',
                column: tCommon('frontTeatsSidewaysAbbr'),
                tooltip: tCommon('frontTeatsSideways'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'teatsForward',
                column: tCommon('teatsForwardAbbr'),
                tooltip: tCommon('teatsForward'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'shortRearTeats',
                column: tCommon('shortRearTeats'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'funnelTeats',
                column: tCommon('funnelTeats'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.other'),
        type: 'grouping',
        subColumns: [
            {
                field: 'udderPurity',
                column: tCommon('udderPurity'),
                type: 'string',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'colorType',
                column: tCommon('colorType'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'mottleType',
                column: tCommon('mottleType'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'headColor',
                column: tCommon('headColor'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'nervousness',
                column: tCommon('nervousness'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.summary'),
        type: 'grouping',
        subColumns: [
            {
                field: 'calculatedFrame',
                column: tCommon('calculatedFrame'),
                type: 'number',
                align: 'right',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'calculatedMusculature',
                column: tCommon('calculatedMusculature'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'calculatedExtremities',
                column: tCommon('calculatedExtremities'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'calculatedUdder',
                column: tCommon('calculatedUdder'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'calculatedTotal',
                column: tCommon('calculatedTotal'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'adjustedMusculature',
                column: tCommon('adjustedMusculature'),
                type: 'number',
                align: 'right',
                formatValue: formatFleckviehResult('calculatedMusculature'),
                sortable: true,
                width: 90,
                verticalName: true,
            },
            {
                field: 'adjustedExtremities',
                column: tCommon('adjustedExtremities'),
                type: 'number',
                align: 'right',
                formatValue: formatFleckviehResult('calculatedExtremities'),
                sortable: true,
                width: 90,
                verticalName: true,
            },
            {
                field: 'adjustedUdder',
                column: tCommon('adjustedUdder'),
                type: 'number',
                align: 'right',
                formatValue: formatFleckviehResult('calculatedUdder'),
                sortable: true,
                width: 90,
                verticalName: true,
            },
            {
                field: 'adjustedTotal',
                column: tCommon('adjustedTotal'),
                type: 'number',
                align: 'right',
                formatValue: formatFleckviehResult('calculatedTotal'),
                sortable: true,
                width: 90,
                verticalName: true,
            },
        ],
    },
    {
        column: tCommon('tableGroupingHeaders.oldRating'),
        type: 'grouping',
        subColumns: [
            {
                field: 'teatsCloseToEachOther',
                column: tCommon('teatsCloseToEachOtherAbbr'),
                tooltip: tCommon('teatsCloseToEachOther'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'backHeightPoints',
                column: t('backHeightPointsAbbr'),
                tooltip: t('backHeightPoints'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'backLengthPoints',
                column: t('backLengthPointsAbbr'),
                tooltip: t('backLengthPoints'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'bodyDepthPoints',
                column: t('bodyDepthPointsAbbr'),
                tooltip: t('bodyDepthPoints'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'firstDefect',
                column: t('firstDefect'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'secondDefect',
                column: t('secondDefect'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'thirdDefect',
                column: t('thirdDefect'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'fourthDefect',
                column: t('fourthDefect'),
                type: 'number',
                align: 'right',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'unknownFlag',
                column: t('unknownFlag'),
                type: 'optionalBoolean',
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'flag',
                column: t('flag'),
                type: 'string',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
            {
                field: 'unknownDate',
                column: t('unknownDate'),
                type: 'string',
                filterTypes: ['null'],
                sortable: true,
                width: 60,
                verticalName: true,
            },
        ],
    },
];
