// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AnimalName, AnimalNameSchema } from 'api/gen/AnimalName';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Date, DateSchema } from 'api/gen/Date';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { LineRegistry, LineRegistrySchema } from 'api/gen/LineRegistry';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroLong, PositiveOrZeroLongSchema } from 'api/gen/PositiveOrZeroLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { SubjectName, SubjectNameSchema } from 'api/gen/SubjectName';

const requiredPart = t.interface({
  areInvoicingDocumentsCreated: t.union([t.boolean, t.null]),
  categoryId: t.union([t.Integer, t.null]),
  id: PositiveLongSchema,
  invoicingDate: t.union([DateSchema, t.null]),
  lineRegistry: LineRegistrySchema,
  name: t.union([AnimalNameSchema, t.null]),
  numOfDaughtersToMeasure: PositiveOrZeroLongSchema,
  numOfMeasuredBreedings: PositiveOrZeroLongSchema,
  numOfMeasuredDaughters: PositiveOrZeroLongSchema,
  registrarName: t.union([SubjectNameSchema, t.null]),
  registrationDate: t.union([DateSchema, t.null]),
});

export const InvoicingDocumentsSchema = excess(requiredPart);

export interface InvoicingDocuments extends t.TypeOf<typeof InvoicingDocumentsSchema> {}
