// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { Email, EmailSchema } from 'api/gen/Email';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PhoneNumber, PhoneNumberSchema } from 'api/gen/PhoneNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { StableName, StableNameSchema } from 'api/gen/StableName';

const requiredPart = t.interface({
  alternativeEmails: t.union([t.array(EmailSchema), t.null]),
  breederId: PositiveLongSchema,
  email: t.union([EmailSchema, t.null]),
  id: PositiveLongSchema,
  name: StableNameSchema,
  note: t.union([t.string, t.null]),
  phone: t.union([PhoneNumberSchema, t.null]),
});

export const StableSchema = excess(requiredPart);

export interface Stable extends t.TypeOf<typeof StableSchema> {}
