// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractTableFilter, AbstractTableFilterSchema } from 'api/gen/AbstractTableFilter';

const requiredPart = t.interface({
  tag: t.literal('MULTISELECT_NUMBER'),
  field: t.string,
  values: t.array(t.number),
});

export const MultiSelectNumberFilterSchema = excess(t.intersection([AbstractTableFilterSchema.type, requiredPart]));

export interface MultiSelectNumberFilter extends t.TypeOf<typeof MultiSelectNumberFilterSchema> {}
