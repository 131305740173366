import {Nullable, useIdFromRoute} from 'cmsch-fe-library';
import {isNull} from 'lodash/fp';
import {useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as routerActions from 'redux-first-history';
import {none} from 'ts-opt';

import {Breeder} from 'api/gen/Breeder';
import {formHelpers} from 'utils/forms';

import {breederAction, simpleBreederSelector} from '../model';
import {UpdateBreederFormValues} from '../types/update-breeder-form-values';

interface UseGetDataForBreederDetail {
    breeder: Nullable<Breeder>;
    setBreederFormValues(breederValues: UpdateBreederFormValues): void;
    goTo(path: string): void;
    goBack(): void;
    saveBreeder(): void;
}

// eslint-disable-next-line max-lines-per-function
export const useGetDataForBreederDetail = (): UseGetDataForBreederDetail => {
    const breeder = useSelector(simpleBreederSelector.breeder);

    const breederId = useIdFromRoute('id');
    const dispatch = useDispatch();

    const setBreederFormValues = useCallback((breederValues: UpdateBreederFormValues) => {
        dispatch(formHelpers.initialize('breederForm', breederValues));
    }, [dispatch]);

    const clearStables = useCallback(() => {
        dispatch(breederAction.clearStables());
    }, [dispatch]);

    const goTo = useCallback((path: string) => {
        dispatch(routerActions.push(path));
    }, [dispatch]);

    const clearBreeder = useCallback(() => {
        dispatch(breederAction.clearBreeder());
    }, [dispatch]);

    const goBack = useCallback(() => {
        dispatch(routerActions.push('/breeders'));
    }, [dispatch]);

    const resetEditedStableId = useCallback(() => {
        dispatch(breederAction.setEditedStableId(none));
    }, [dispatch]);

    const saveBreeder = useCallback(() => {
        if (!isNull(breeder)) dispatch(breederAction.updateBreeder(breeder.id));
    }, [breeder, dispatch]);

    useEffect(() => {
        clearBreeder();

        return (): void => clearBreeder();
    }, [clearBreeder]);

    useEffect(() => {
        clearStables();

        return (): void => clearStables();
    }, [clearStables]);

    useEffect(() => (): void => {
        resetEditedStableId();
    }, [resetEditedStableId]);

    useEffect(() => {
        dispatch(breederAction.getBreeder(breederId));
    }, [breederId, dispatch]);

    useEffect(() => {
        if (!isNull(breeder)) dispatch(breederAction.getStables(breeder.id));
    }, [breeder, dispatch]);

    return ({
        breeder,
        goBack,
        goTo,
        saveBreeder,
        setBreederFormValues,
    });
};
