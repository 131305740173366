// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';

const requiredPart = t.interface({
  ids: t.array(PositiveLongSchema),
});

export const IdSetSchema = excess(requiredPart);

export interface IdSet extends t.TypeOf<typeof IdSetSchema> {}
