import {ColorAnnotation, TableDataRequest, TableLegend, TableRowBackground, ToolbarButton} from 'cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import React, {FC, Fragment, memo, useCallback, useMemo} from 'react';
import {Opt} from 'ts-opt';

import {InvoicingDocuments} from 'api/gen/InvoicingDocuments';
import {simpleBullSelector} from 'app/bull/model';
import {TFunction, useOurTranslation} from 'app/translations';
import {LoadingItemId} from 'common/layout/types/loading-item-id';
import {ConnectedTable} from 'utils/tables';

import {useGetActionSettings} from './action-settings';
import {getColumns} from './columns';

const countOfDaughtersToHighlight = 50;

const getTableAnnotations = (t: TFunction<'bull/invoicesTable'>): Array<ColorAnnotation> => [
    {
        color: 'success',
        label: t('annotation'),
    },
];

interface Props {
    loadingItems: Array<LoadingItemId>;
    selectedBullsIds: Array<number>;
    getInvoices(tableDataRequest: Opt<TableDataRequest<InvoicingDocuments>>): void;
    createInvoicingDocuments(): void;
    toggleSelectBulls(ids: Array<number>): void;
    getInvoicingDocuments(bullId: number): void;
    exportBullDaughters(bullId: number): void;
    goTo(path: string): void;
}

const pathnamesForRefresh: Array<RegExp> = [/^\/invoicing-documents\/daughters\/.+/];

const InvoicesTableBase: FC<Props> = ({
    loadingItems,
    selectedBullsIds,
    getInvoices,
    createInvoicingDocuments,
    toggleSelectBulls,
    getInvoicingDocuments,
    exportBullDaughters,
    goTo,
}) => {
    const {t, tCommon} = useOurTranslation('bull/invoicesTable');

    const noSelectedBulls = useMemo(() => isEmpty(selectedBullsIds), [selectedBullsIds]);

    const createInvoicingDocumentsTitle = useMemo(() => t('createInvoicingDocuments', {count: selectedBullsIds.length})
        , [selectedBullsIds.length, t]);

    const deselectAllBulls = useCallback(() => {
        toggleSelectBulls([]);
    }, [toggleSelectBulls]);

    const calcRowBackgroundDesign = useCallback((
        row: InvoicingDocuments,
        _index: number,
    ): TableRowBackground | undefined =>
        !row.areInvoicingDocumentsCreated && row.numOfMeasuredDaughters >= countOfDaughtersToHighlight
            ? 'success'
            : undefined
    , []);

    const actionSettings = useGetActionSettings({
        selectedBullsIds,
        loadingItems,
        toggleSelectBulls,
        exportBullDaughters,
        getInvoicingDocuments,
        goTo,
    });

    const headerButtons = useMemo((): Array<ToolbarButton> => [
        {
            title: createInvoicingDocumentsTitle,
            icon: 'fileExcelOutlined',
            visuals: 'primary',
            loading: loadingItems.includes('createInvoicingDocuments'),
            disabled: noSelectedBulls || loadingItems.includes('createInvoicingDocuments'),
            disabledTitle: loadingItems.includes('createInvoicingDocuments')
                ? tCommon('actionPending') : t('selectAtLeastOneBull'),
            onClick: createInvoicingDocuments,
            testId: 'create-invoices',
        },
        {
            title: tCommon('deselectAll'),
            icon: 'closeOutlined',
            visuals: 'primary',
            disabled: noSelectedBulls,
            disabledTitle: t('selectAtLeastOneBull'),
            onClick: deselectAllBulls,
            testId: 'deselect-all-invoices',
        },
    ], [
        createInvoicingDocuments,
        deselectAllBulls,
        createInvoicingDocumentsTitle,
        loadingItems,
        noSelectedBulls,
        t,
        tCommon,
    ]);

    const emptyTableMessages = useMemo(() => ({noRows: t('noInvoicesAvailable')}), [t]);

    const columns = useMemo(() => getColumns(t, tCommon), [t, tCommon]);

    return (
        <Fragment>
            <ConnectedTable
                tableId="invoices"
                columns={columns}
                rowDataPageSelector={simpleBullSelector.invoicesPage}
                onFetchTableData={getInvoices}
                actionSettings={actionSettings}
                headerButtons={headerButtons}
                calcRowBackgroundDesign={calcRowBackgroundDesign}
                emptyTableMessages={emptyTableMessages}
                pathnamesForRefresh={pathnamesForRefresh}
                rowActionsOnRight
                highlightOnEdit
                showFulltextFilter
            />
            <div className="mt-2">
                <TableLegend annotations={getTableAnnotations(t)} />
            </div>
        </Fragment>

    );
};

export const InvoicesTable = memo(InvoicesTableBase);
