import {usePrevious} from 'cmsch-fe-library';
import React, {useState, useCallback, PropsWithChildren, useEffect, memo, useMemo, FC} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {opt} from 'ts-opt';

import {UserDetails} from 'api/gen/UserDetails';
import {homePagePath} from 'app/consts';
import {useOurTranslation} from 'app/translations';
import {AntLayout} from 'common/ant';
import {ICONS} from 'common/buttons/icons';
import {MainMenu} from 'common/layout';
import {getMenuItems} from 'common/layout/components/main-menu/menu-items';

import {AppLayout} from './app-layout';
import {userAction} from './user';

import styles from './styles.sass';

interface Props {
    currentUser: UserDetails;
}

const MenuAppWrapperBase: FC<PropsWithChildren<Props>> = props => {
    const {children, currentUser} = props;

    const {t} = useOurTranslation('mainMenu');
    const {pathname} = useLocation();
    const prevPathname = usePrevious(pathname);

    const dispatch = useDispatch();
    const setPreviousAndCurrentPathname = useCallback(() => {
        dispatch(userAction.setPreviousAndCurrentPathname(opt(prevPathname), opt(pathname)));
    }, [dispatch, pathname, prevPathname]);
    useEffect(() => {
        if (prevPathname !== pathname) setPreviousAndCurrentPathname();
    }, [pathname, prevPathname]); // eslint-disable-line react-hooks/exhaustive-deps

    const [isSiderActive, setIsSiderActive] = useState(false);
    const [isSideMenuCollapsed, setIsSideMenuCollapsed] = useState(true);

    const handleSideMenuCollapse = useCallback(() => {
        setIsSideMenuCollapsed(true);
    }, []);

    const menuItems = useMemo(() => getMenuItems(opt(currentUser), t), [currentUser, t]);
    const standardMenu = useMemo(() => (
        <MainMenu
            menuItems={menuItems}
            userRole={currentUser.role}
            locationPath={pathname}
            homePath={homePagePath}
            isUserLoading={false}
            menuType={isSiderActive ? 'small' : 'full'}
            languageOptions={[]}
            environment="development"
            isSideMenuCollapsed={isSideMenuCollapsed}
        />
    ), [currentUser, isSideMenuCollapsed, isSiderActive, pathname, menuItems]);

    const sideMenu = useMemo(() => (
        <MainMenu
            menuItems={menuItems}
            userRole={currentUser.role}
            locationPath={pathname}
            homePath={homePagePath}
            isUserLoading={false}
            menuType="side"
            languageOptions={[]}
            onMenuItemClick={handleSideMenuCollapse}
            environment="development"
            isSideMenuCollapsed={isSideMenuCollapsed}
        />
    )
    , [currentUser, handleSideMenuCollapse, isSideMenuCollapsed, pathname, menuItems]);

    return (
        <AppLayout header={standardMenu}>
            <div data-test-id="side-menu">
                <AntLayout.Sider
                    width="270px"
                    breakpoint="sm"
                    collapsedWidth="0"
                    collapsed={isSideMenuCollapsed}
                    onBreakpoint={setIsSiderActive}
                    onCollapse={setIsSideMenuCollapsed}
                    className={isSiderActive ? styles.menuAppWrapperSiderActive : 'd-none'}
                    trigger={ICONS[isSideMenuCollapsed ? 'barsOutlined' : 'closeOutlined']}
                >
                    {sideMenu}
                </AntLayout.Sider>
            </div>
            {isSiderActive && !isSideMenuCollapsed && (
                <div
                    className={styles.menuAppWrapperSiderModalBackdrop}
                    onClick={handleSideMenuCollapse}
                />
            )}
            {children}
        </AppLayout>
    );
};

export const MenuAppWrapper = memo(MenuAppWrapperBase);
