import {TypedFormSectionInjectedProps} from 'cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairFleckviehMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairFleckviehMeasurementFormValues, 'properties'>;

const UdderDefectsFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="edematicUdder"
                label={tCommon('edematicUdder')}
                isRequired
            />
            <Fields.NumberInput
                name="gradualUdder"
                label={tCommon('gradualUdder')}
                isRequired
            />
            <Fields.NumberInput
                name="frontTeatsSideways"
                label={tCommon('frontTeatsSideways')}
                isRequired
            />
            <Fields.NumberInput
                name="teatsForward"
                label={tCommon('teatsForward')}
                isRequired
            />
            <Fields.NumberInput
                name="shortRearTeats"
                label={tCommon('shortRearTeats')}
                isRequired
            />
            <Fields.NumberInput
                name="funnelTeats"
                label={tCommon('funnelTeats')}
                isRequired
            />
        </Fragment>
    );
};

export const UdderDefectsFormSection = memo(UdderDefectsFormSectionBase);
