import {
    putAll,
    showBeError,
    downloadFileFromResponse,
    takeLatestF,
    Response,
    beYearmonthFormat,
} from 'cmsch-fe-library';
import moment, {Moment} from 'moment';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {select, put, call} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {simpleUserSelector} from 'app/user';
import {layoutAction} from 'common/layout';
import {formHelpers} from 'utils/forms';

import {ExportUserMeasurementsFormValues} from '../../types/export-user-measurements-form-values';
import {ExportUserMeasurementsType} from '../../types/export-user-measurements-type';
import {ExportUserMeasurementsAction} from '../action';

const defaultExportFileName = 'export.txt';

function* getUserIds(
    exportFormValues: ExportUserMeasurementsFormValues,
    isAdmin: boolean,
): SagaIterator<Array<number> | null> {
    if (!isAdmin) return null;

    const allUsers = opt(yield* select(simpleUserSelector.allUsers))
        .orCrash('All users list not fetched');
    const userItem = opt(exportFormValues.userItem).orCrash('user not set');

    return userItem >= 0 ? [userItem] : allUsers.map(({id}) => id);
}

const getMonthYear = (exportFormValues: ExportUserMeasurementsFormValues): Moment =>
    opt(exportFormValues.monthYear)
        .map(x => moment(x, 'YYYY-M'))
        .orCrash('monthYear not set');

function* exportMeasurements(
    exportType: ExportUserMeasurementsType,
    exportFormValues: ExportUserMeasurementsFormValues,
    isAdmin: boolean,
): SagaIterator<Response<ArrayBuffer>> {
    const userIds = yield* call(getUserIds, exportFormValues, isAdmin);
    const yearMonth = getMonthYear(exportFormValues).format(beYearmonthFormat);

    switch (exportType) {
        case ExportUserMeasurementsType.DEAD:
            return yield* call(Api.exportDeadAnimals, {yearMonth, userIds});
        case ExportUserMeasurementsType.MEASURED:
            return yield* call(Api.exportMeasurements, {yearMonth, userIds, testing: null});
        case ExportUserMeasurementsType.TEST:
            return yield* call(Api.exportMeasurements, {yearMonth, userIds, testing: true});
        case ExportUserMeasurementsType.RAW:
            return yield* call(Api.exportRawMeasurements, {yearMonth, userIds});
        case ExportUserMeasurementsType.REPAIRS:
            return yield* call(Api.exportMeasurementRepairs, {yearMonth, userIds});
    }
}

function* execute({payload: {exportType}}: ExportUserMeasurementsAction): SagaIterator {
    yield* put(layoutAction.setItemLoading('exportMeasurements', true));

    const exportFormValues = (yield* select(formHelpers.formValues('exportUserMeasurements')))
        .orCrash('Export form values not present');
    const isAdmin = yield* select(simpleUserSelector.isAdmin);
    const response = yield* call(exportMeasurements, exportType, exportFormValues, isAdmin);

    if (!response.isSuccess) {
        yield putAll(showBeError(response, t('planning/messages')('exportError')));
    } else {
        yield* call(downloadFileFromResponse, response, defaultExportFileName);
    }

    yield* put(layoutAction.setItemLoading('exportMeasurements', false));
}

export function* exportUserMeasurementsSaga(): SagaIterator {
    yield takeLatestF('planning/EXPORT_USER_MEASUREMENTS', execute);
}
