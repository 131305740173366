import {Options} from 'cmsch-fe-library';
import React, {FC, memo, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Opt} from 'ts-opt';

import {AnimalWithBreedingSearch} from 'api/gen/AnimalWithBreedingSearch';
import {MeasurementDetail} from 'api/gen/MeasurementDetail';
import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';
import {RepairMeasurementTabKey} from 'app/repair/types/repair-measurement-tab-key';
import {useOurTranslation} from 'app/translations';
import {AntTabs} from 'common/ant';
import {FooterButtons} from 'common/buttons';
import {formHelpers, useForm} from 'utils/forms';

import {RepairMeasurementShowFieldWrapper} from '../repair-measurement-show-field-wrapper';
import {BodyStructureDefectsFormSection} from './body-structure-defects-form-section';
import {BodyStructureFormSection} from './body-structure-form-section';
import {ExtremitiesDefectsFormSection} from './extremities-defects-form-section';
import {ExtremitiesFormSection} from './extremities-form-section';
import {LactationPerformanceFormSection} from './lactation-performance-form-section';
import {OtherFormSection} from './other-form-section';
import {SummaryFormSection} from './summary-form-section';
import {UdderDefectsFormSection} from './udder-defects-form-section';
import {UdderFormSection} from './udder-form-section';
import {validate} from './validate';
import {
    RepairHolsteinMeasurementFormValues,
    repairHolsteinMeasurementInitialValues,
    repairHolsteinMeasurementForm,
} from './values';

interface Props {
    measurementDetail: MeasurementDetail;
    foundAnimalsOptions: Options<string>;
    foundAnimal: Opt<AnimalWithBreedingSearch>;
    findAnimalsInProgress: boolean;
    onSubmit(values: RepairHolsteinMeasurementFormValues): void;
    findAnimals(earTag: string): void;
}
// eslint-disable-next-line max-lines-per-function
const RepairHolsteinMeasurementFormBase: FC<Props> = props => {
    const {measurementDetail, foundAnimalsOptions, foundAnimal, findAnimalsInProgress, onSubmit, findAnimals} = props;
    const {breedingNumber, stableName, breederName} = measurementDetail;

    const {tCommon} = useOurTranslation('common');

    const {submitting, Fields, Form, pristine} = useForm({
        form: repairHolsteinMeasurementForm,
        initialValues: repairHolsteinMeasurementInitialValues,
        onSubmit,
        validate,
    });

    const [activeKey, setActiveKey] = useState<RepairMeasurementTabKey>('general');

    const valid = useSelector(formHelpers.isValid('repairHolsteinMeasurement'));

    const dispatch = useDispatch();
    const onChange = useCallback((newActiveKey: string) => {
        if (valid) {
            setActiveKey(newActiveKey as RepairMeasurementTabKey);
        } else {
            dispatch(formHelpers.submit('repairHolsteinMeasurement'));
        }
    }, [dispatch, valid]);

    const PropertiesSectionField = Fields.genSection<'properties', PropertiesFormSectionProps>();

    return (
        <Form>
            <AntTabs
                activeKey={activeKey}
                onChange={onChange}
            >
                <AntTabs.TabPane
                    tab={tCommon(`tableGroupingHeaders.general`)}
                    key="general"
                >
                    <Fields.StringSelect
                        name="earTag"
                        label={tCommon('earTag')}
                        options={foundAnimalsOptions}
                        searchable
                        clearable
                        onSearch={findAnimals}
                        includeValueToOptions
                        isRequired
                        fieldIsLoading={findAnimalsInProgress}
                    />
                    <Fields.DateInput
                        name="ratingDate"
                        label={tCommon('ratingDate')}
                        isRequired
                        dontIncludeFuture
                    />
                    <RepairMeasurementShowFieldWrapper
                        label={tCommon('breeding')}
                        value={foundAnimal.prop('breedingNumber').altOpt(breedingNumber)}
                    />
                    <RepairMeasurementShowFieldWrapper
                        label={tCommon('stable')}
                        value={foundAnimal.prop('stableName').altOpt(stableName)}
                    />
                    <RepairMeasurementShowFieldWrapper
                        label={tCommon('company')}
                        value={foundAnimal.prop('breederName').altOpt(breederName)}
                    />
                    <Fields.NumberInput
                        name="lactation"
                        label={tCommon('lactation')}
                        isRequired
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={tCommon(`tableGroupingHeaders.lactationPerformance`)}
                    key="lactationPerformance"
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={LactationPerformanceFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={tCommon(`tableGroupingHeaders.bodyStructure`)}
                    key="bodyStructure"
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={BodyStructureFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={tCommon(`tableGroupingHeaders.bodyStructureDefects`)}
                    key="bodyStructureDefects"
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={BodyStructureDefectsFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={tCommon(`tableGroupingHeaders.udder`)}
                    key="udder"
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={UdderFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={tCommon(`tableGroupingHeaders.udderDefects`)}
                    key="udderDefects"
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={UdderDefectsFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={tCommon(`tableGroupingHeaders.extremities`)}
                    key="extremities"
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={ExtremitiesFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={tCommon(`tableGroupingHeaders.extremitiesDefects`)}
                    key="extremitiesDefects"
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={ExtremitiesDefectsFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={tCommon(`tableGroupingHeaders.summary`)}
                    key="summary"
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={SummaryFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={tCommon(`tableGroupingHeaders.other`)}
                    key="other"
                >
                    <PropertiesSectionField
                        name="properties"
                        Component={OtherFormSection}
                        props={{tCommon}}
                    />
                </AntTabs.TabPane>
            </AntTabs>
            <FooterButtons
                withoutCancel
                disabledSubmit={submitting || pristine}
            />
        </Form>
    );
};

export const RepairHolsteinMeasurementForm = memo(RepairHolsteinMeasurementFormBase);
