import {Options} from 'cmsch-fe-library';
import React, {FC, memo, useCallback} from 'react';
import {Opt} from 'ts-opt';

import {FindAllApprovedByAnimalQueryParams} from 'api/gen/Api';
import {useOurTranslation} from 'app/translations';
import {useForm} from 'utils/forms';

import {validate} from './validate';
import {searchMeasurementsInitialValues, searchMeasurementsFormName} from './values';

interface Props {
    foundEarTags: Options<string>;
    findEarTagsInProgress: boolean;
    onSubmit(params: FindAllApprovedByAnimalQueryParams): void;
    findEarTags(value: string): void;
}

const SearchMeasurementsFormBase: FC<Props> = props => {
    const {foundEarTags, findEarTagsInProgress, onSubmit, findEarTags} = props;

    const {tCommon} = useOurTranslation('common');

    const onAction = useCallback((value: Opt<string>) => {
        value.onSome(x => onSubmit({earTag: x}));
    }, [onSubmit]);

    const onChange = useCallback((value: Opt<string>) => {
        value.onSome(x => onSubmit({earTag: x}));
    }, [onSubmit]);

    const {submitting, Fields, Form} = useForm({
        form: searchMeasurementsFormName,
        initialValues: searchMeasurementsInitialValues,
        onSubmit,
        validate,
    });

    return (
        <Form>
            <Fields.StringSelect
                name="earTag"
                label={tCommon('earTag')}
                disabled={submitting}
                options={foundEarTags}
                searchable
                clearable
                onSearch={findEarTags}
                onAction={onAction}
                onFieldChange={onChange}
                fieldIsLoading={findEarTagsInProgress}
                isRequired
                biggerLabel
            />
        </Form>
    );
};

export const SearchMeasurementsForm = memo(SearchMeasurementsFormBase);
