import {showBeError, putAll, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {none, opt} from 'ts-opt';
import {call, put, select} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {
    convertTableDataParamsToDto,
    getRequestedTablePageParams,
    simpleTableSelector,
    tablesAction,
} from 'utils/tables';

import {breedingAction, GetAnimalsInBreedingAction} from '../action';

function* execute({payload: {breedingId, tableDataRequest}}: GetAnimalsInBreedingAction): SagaIterator {
    if (tableDataRequest.isEmpty) {
        return yield* put(breedingAction.getAnimalsInBreedingSuccess(none));
    }

    yield* put(tablesAction.tableDataFetchStart('animalsInBreeding'));

    const tableState = yield* select(simpleTableSelector.tableState('animalsInBreeding'));

    const tableDataParams = getRequestedTablePageParams({
        tableId: 'animalsInBreeding',
        tableDataRequest: tableDataRequest.orCrash('No tableDataRequest'),
        tableState,
    },
    );

    const {filters, params} = convertTableDataParamsToDto({tableDataParams});

    const response = yield* call(Api.findAllAnimalsForPlanning, filters, params, {breedingId});

    if (response.isSuccess) {
        yield* put(breedingAction.getAnimalsInBreedingSuccess(opt(response.data)));
        yield* put(tablesAction.tableDataFetchSuccess('animalsInBreeding', tableDataParams));
    } else {
        yield putAll(showBeError(response, t('breeding/sagas')('loadingOfCows')));
    }
}

export function* getAnimalsInBreedingSaga(): SagaIterator {
    yield takeLatestF('breeding/GET_ANIMALS_IN_BREEDING', execute);
}
