import React, {FC, memo, ReactNode} from 'react';

import {Ant} from 'common/ant';

import styles from './styles.sass';

interface OuterProps {
    rowName: ReactNode;
    rowValue: string | undefined;
}

type Props = OuterProps;

const AppInfoRowBase: FC<Props> = ({rowName, rowValue}) => (
    <Ant.Row gutter={16}>
        <Ant.Col
            xs={13}
            sm={9}
            lg={5}
            xl={4}
        >
            {rowName}
        </Ant.Col>
        <Ant.Col
            xs={11}
            sm={15}
            lg={19}
            xl={20}
            className={styles.versionColumn}
        >
            <Ant.Typography.Text strong>{rowValue}</Ant.Typography.Text>
        </Ant.Col>
    </Ant.Row>
);

export const AppInfoRow = memo(AppInfoRowBase);
