import {TypedFormSectionInjectedProps} from 'cmsch-fe-library';
import React, {Fragment, FC, memo} from 'react';

import {PropertiesFormSectionProps} from 'app/repair/types/properties-form-section-props';

import {RepairHolsteinMeasurementFormValues} from './values';

type Props = PropertiesFormSectionProps
& TypedFormSectionInjectedProps<RepairHolsteinMeasurementFormValues, 'properties'>;

const BodyStructureFormSectionBase: FC<Props> = props => {
    const {Fields, tCommon} = props;

    return (
        <Fragment>
            <Fields.NumberInput
                name="backWidth"
                label={tCommon('backWidth')}
                isRequired
            />
            <Fields.NumberInput
                name="backAngle"
                label={tCommon('backAngle')}
                isRequired
            />
            <Fields.NumberInput
                name="frame"
                label={tCommon('frame')}
                isRequired
            />
            <Fields.NumberInput
                name="bodyDepth"
                label={tCommon('bodyDepth')}
                isRequired
            />
            <Fields.NumberInput
                name="chestWidth"
                label={tCommon('chestWidth')}
                isRequired
            />
        </Fragment>
    );
};

export const BodyStructureFormSection = memo(BodyStructureFormSectionBase);
