// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { BreedingNumber, BreedingNumberSchema } from 'api/gen/BreedingNumber';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { Codex, CodexSchema } from 'api/gen/Codex';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { CountryCode, CountryCodeSchema } from 'api/gen/CountryCode';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { DateTime, DateTimeSchema } from 'api/gen/DateTime';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveInteger, PositiveIntegerSchema } from 'api/gen/PositiveInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveLong, PositiveLongSchema } from 'api/gen/PositiveLong';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { PositiveOrZeroInteger, PositiveOrZeroIntegerSchema } from 'api/gen/PositiveOrZeroInteger';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { ShortEarTag, ShortEarTagSchema } from 'api/gen/ShortEarTag';
// @ts-ignore: Workaround for "is declared but its value is never read."
import { UdderPurity, UdderPuritySchema } from 'api/gen/UdderPurity';

const requiredPart = t.interface({
  adjustedExtremities: PositiveIntegerSchema,
  adjustedMusculature: PositiveIntegerSchema,
  adjustedTotal: PositiveIntegerSchema,
  adjustedUdder: PositiveIntegerSchema,
  animalId: t.union([PositiveLongSchema, t.null]),
  ankleJoint: PositiveIntegerSchema,
  backAngle: PositiveIntegerSchema,
  backHeight: PositiveIntegerSchema,
  backLength: PositiveIntegerSchema,
  backWidth: PositiveIntegerSchema,
  bodyDepth: PositiveIntegerSchema,
  bodyLength: PositiveIntegerSchema,
  breedingId: BreedingNumberSchema,
  calculatedMusculature: PositiveIntegerSchema,
  chestCircumference: PositiveIntegerSchema,
  codex: CodexSchema,
  colorType: PositiveIntegerSchema,
  countryCode: CountryCodeSchema,
  edematicUdder: PositiveOrZeroIntegerSchema,
  extremities: PositiveIntegerSchema,
  fitness: t.number,
  frame: PositiveIntegerSchema,
  frontExtremitiesOpenPosture: PositiveOrZeroIntegerSchema,
  frontTeatsPlacement: PositiveIntegerSchema,
  frontTeatsSideways: PositiveOrZeroIntegerSchema,
  frontUdderAttachmentAngle: PositiveIntegerSchema,
  frontUdderLength: PositiveIntegerSchema,
  funnelTeats: PositiveOrZeroIntegerSchema,
  gradualUdder: PositiveOrZeroIntegerSchema,
  headColor: PositiveOrZeroIntegerSchema,
  hoovesBackHeight: PositiveIntegerSchema,
  hoovesTorsion: PositiveOrZeroIntegerSchema,
  isDead: t.boolean,
  lactationOrder: PositiveIntegerSchema,
  lastMilkingAt: PositiveIntegerSchema,
  looseLoins: PositiveOrZeroIntegerSchema,
  looseShoulderBlade: PositiveOrZeroIntegerSchema,
  mottleType: PositiveIntegerSchema,
  musculature: PositiveIntegerSchema,
  nervousness: PositiveOrZeroIntegerSchema,
  openedHooves: PositiveOrZeroIntegerSchema,
  pastern: PositiveIntegerSchema,
  posture: PositiveOrZeroIntegerSchema,
  ratingDateTime: DateTimeSchema,
  ratingTimeInSeconds: t.union([t.Integer, t.null]),
  rearPosture: PositiveIntegerSchema,
  rearTeatsPlacement: PositiveIntegerSchema,
  rearTeatsPosition: PositiveIntegerSchema,
  rearUdderAttachment: PositiveIntegerSchema,
  rearUdderLength: PositiveIntegerSchema,
  roofedBack: PositiveOrZeroIntegerSchema,
  sampleId: ShortEarTagSchema,
  suspensoryLigament: PositiveIntegerSchema,
  teatsCloseToEachOther: PositiveOrZeroIntegerSchema,
  teatsForward: PositiveOrZeroIntegerSchema,
  teatsLength: PositiveIntegerSchema,
  teatsWidth: PositiveIntegerSchema,
  total: PositiveIntegerSchema,
  udder: PositiveIntegerSchema,
  udderDepth: PositiveIntegerSchema,
  udderPurity: UdderPuritySchema,
  weakBack: PositiveOrZeroIntegerSchema,
  withersHeight: t.union([PositiveIntegerSchema, t.null]),
});

export const FleckviehLegacyMeasurementSchema = excess(requiredPart);

export interface FleckviehLegacyMeasurement extends t.TypeOf<typeof FleckviehLegacyMeasurementSchema> {}
