import {classNames} from 'cmsch-fe-library';
import React, {Fragment, FC, memo, ReactNode} from 'react';
import {Opt} from 'ts-opt';

import {AnimalMeasurementSearch} from 'api/gen/AnimalMeasurementSearch';
import {useOurTranslation} from 'app/translations';

import styles from './styles.sass';

interface Props {
    measurementSearch: Opt<AnimalMeasurementSearch>;
}

const rendertem = (label: string, value: Opt<string>): ReactNode => (
    <div className="row h-50 align-content-center">
        {value.map(x => (
            <Fragment key={x}>
                <div className="col-6">
                    {label}:
                </div>
                <div className="col-6 text-right">
                    {x}
                </div>
            </Fragment>
        )).orNull()}
    </div>
);

const AnimalInfoBase: FC<Props> = props => {
    const {measurementSearch} = props;

    const {tCommon} = useOurTranslation('common');

    return (
        <div className={classNames('col-12', 'col-md-4', 'px-md-2', 'px-lg-3', 'px-xl-5', styles.border)}>
            {rendertem(tCommon('breeding'), measurementSearch.prop('breedingNumber'))}
            {rendertem(tCommon('stable'), measurementSearch.prop('stableName'))}
        </div>
    );
};

export const AnimalInfo = memo(AnimalInfoBase);
