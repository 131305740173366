import {putAll, showBeError, showSuccess, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';
import {layoutAction} from 'common/layout';

import {planningAction, RemoveBreederFromPlanAction} from '../action';

function* execute({payload: {animalIds}}: RemoveBreederFromPlanAction): SagaIterator {
    const title = t('planning/messages')('breederRemoveTitle');

    yield* put(layoutAction.setItemLoading('plan', true));

    const response = yield* call(Api.removeAnimalsFromPlan, {animalIds});

    if (response.isSuccess) {
        yield* put(planningAction.getPlan());
        yield* put(showSuccess(title, t('planning/messages')('breederRemoveSuccess')));
    } else {
        yield putAll(showBeError(response, title));
    }

    yield* put(layoutAction.setItemLoading('plan', false));
}

export function* removeBreederFromPlan(): SagaIterator {
    yield takeLatestF('planning/REMOVE_BREEDER_FROM_PLAN', execute);
}
