// Generated via oats. Do NOT modify manually.

import * as t from 'io-ts';
import {excess} from 'cmsch-fe-library';

// @ts-ignore: Workaround for "is declared but its value is never read."
import { AbstractTableFilter, AbstractTableFilterSchema } from 'api/gen/AbstractTableFilter';

const requiredPart = t.interface({
  tag: t.literal('BETWEEN_DECIMALS'),
  field: t.string,
  from: t.number,
  to: t.number,
});

export const BetweenDecimalsFilterSchema = excess(t.intersection([AbstractTableFilterSchema.type, requiredPart]));

export interface BetweenDecimalsFilter extends t.TypeOf<typeof BetweenDecimalsFilterSchema> {}
