import {Options} from 'cmsch-fe-library';
import {sortBy} from 'lodash/fp';
import React, {FC, useMemo, useCallback, memo} from 'react';
import {Opt} from 'ts-opt';

import {StableWithBreedings} from 'api/gen/StableWithBreedings';
import {useOurTranslation} from 'app/translations';
import {FooterButtons} from 'common/buttons';
import {useForm} from 'utils/forms';

import {
    selectBreedingFormName,
    SelectBreedingFormValues,
    selectBreedingInitialValues,
} from '../../types/select-breeding-form-values';
import {validate} from './validate';

const getStableOptions = (stables: Array<StableWithBreedings>): Options<number> =>
    sortBy<StableWithBreedings>(x => x.name)(stables)
        .map(stable => ({value: stable.id, label: `${stable.name} (${stable.breederName})`}));

const getBreedingOptions = (stableId: number | null, stables: Array<StableWithBreedings>): Options<number> =>
    stables.find(x => x.id === stableId)?.breedings
        .map(breeding => ({value: breeding.id, label: breeding.breedingNumber})) ?? [];

interface Props {
    currentValues: SelectBreedingFormValues | null;
    stables: Array<StableWithBreedings>;
    findEarTagsInProgress: boolean;
    onSubmit(): void;
    onCancel(): void;
}

const SelectBreedingFormBase: FC<Props> = props => {
    const {onSubmit, onCancel, stables, currentValues, findEarTagsInProgress} = props;
    const {t} = useOurTranslation('common');

    const stableOptions = useMemo(() => getStableOptions(stables), [stables]);
    const breedingOptions = useMemo(() =>
        getBreedingOptions(currentValues?.stableId ?? null, stables), [currentValues?.stableId, stables]);

    const {submitting, change, Fields, Form} = useForm({
        form: selectBreedingFormName,
        initialValues: selectBreedingInitialValues,
        enableReinitialize: true,
        keepDirtyOnReinitialize: false,
        onSubmit,
        validate,
    });

    const onStableSelect = useCallback((stableId: Opt<number>): void => {
        const stable = stables.find(x => x.id === stableId.orNull());
        const breedings = stable?.breedings ?? [];
        const breedingId = breedings.length === 1 ? breedings[0].id : null;

        change('breedingId', breedingId);
    }, [change, stables]);

    return (
        <Form>
            <Fields.NumberSelect
                name="stableId"
                label={t('stable')}
                disabled={submitting}
                options={stableOptions}
                onFieldChange={onStableSelect}
                fieldIsLoading={findEarTagsInProgress}
                clearable
                isRequired
                searchable
            />
            <Fields.NumberSelect
                name="breedingId"
                label={t('breeding')}
                disabled={submitting || !currentValues?.stableId}
                options={breedingOptions}
                clearable
                isRequired
            />
            <div className="mt-3">
                <FooterButtons
                    onCancel={onCancel}
                    submitting={submitting}
                />
            </div>
        </Form>
    );
};

export const SelectBreedingForm = memo(SelectBreedingFormBase);
