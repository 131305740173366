import {Options, beYearmonthFormat} from 'cmsch-fe-library';
import moment from 'moment';

const monthsInYear = 12;
const earliestAvailableMonthYear = {year: 2018, month: 7};

interface MonthYear {
    month: number;
    year: number;
}

export const generateMonths = (startMY: MonthYear, stopMY: MonthYear): Array<string> => {
    const result: Array<string> = [];
    let nextMY = startMY;

    while (nextMY.year < stopMY.year || (nextMY.year === stopMY.year && nextMY.month <= stopMY.month)) {
        result.push(`${nextMY.year}-${nextMY.month}`);
        const lastMonthOfYear = nextMY.month === monthsInYear;
        nextMY = {
            month: lastMonthOfYear ? 1 : nextMY.month + 1,
            year: lastMonthOfYear ? nextMY.year + 1 : nextMY.year,
        };
    }

    return result;
};

export const generateDescendingMonthsOptions = (): Options<string> => {
    const now = moment();
    const stopMY: MonthYear = {year: now.year(), month: now.month() + 1};
    const descendingMonths = generateMonths(earliestAvailableMonthYear, stopMY).reverse();

    return descendingMonths.map(my => ({value: moment(my, 'YYYY-M').format(beYearmonthFormat), label: my}));
};
