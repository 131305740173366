import {putAll, showBeError, showSuccess, takeLatestF} from 'cmsch-fe-library';
import {SagaIterator} from 'redux-saga';
import {opt} from 'ts-opt';
import {call, put} from 'typed-redux-saga';

import {Api} from 'api/gen/Api';
import {t} from 'app/translations';

import {DeleteMeasurementAction, measurementAction} from '../action';

const tt = t('measurement/messages');

function* execute({payload: {ratingId}}: DeleteMeasurementAction): SagaIterator {
    const response = yield* call(Api.deleteRating, {ratingId});

    if (response.isSuccess) {
        yield* put(measurementAction.getMeasurements(opt({action: 'refresh'})));
        yield* put(showSuccess(tt('deleteRatingTitle'), tt('deleteRatingSuccess')));
    } else {
        yield putAll(showBeError(response, tt('deleteRatingTitle')));
    }
}

export function* deleteMeasurementSaga(): SagaIterator {
    yield takeLatestF('measurement/DELETE_MEASUREMENT', execute);
}
