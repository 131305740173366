import {TableDataRequest, Nullable, useIdFromRoute} from 'cmsch-fe-library';
import {difference, isEmpty} from 'lodash/fp';
import React, {FC, memo, useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as routerActions from 'redux-first-history';
import {Opt} from 'ts-opt';

import {BreedingDetails} from 'api/gen/BreedingDetails';
import {Plan} from 'api/gen/Plan';
import {PlanningAnimal} from 'api/gen/PlanningAnimal';
import {planningAction} from 'app/planning/model/action';
import {simplePlanningSelector} from 'app/planning/model/selector';
import {useOurTranslation} from 'app/translations';
import {Content, PageHeader} from 'common/layout';

import {AnimalsTable, BreedingDetailActionPanel} from '../components';
import {breedingAction, simpleBreedingSelector} from '../model';
import {getAnimalIdsInPlan} from '../utils';
import {getAnimalsInPlanForBreeder} from '../utils/get-animal-ids-in-plan';

const getCountOfStableAnimalsInPlan = (breedingId: number, plan: Nullable<Plan>): number =>
    getAnimalsInPlanForBreeder(breedingId, plan).length;

const getTitle = (breeding: Nullable<BreedingDetails>): string =>
    breeding ? `${breeding.breederName} - ${breeding.stableName} (${breeding.breedingNumber})` : '';

const BreedingDetailBase: FC = _ => {
    const breeding = useSelector(simpleBreedingSelector.breeding);
    const plan = useSelector(simplePlanningSelector.plan);
    const animalIdsInPlan = useMemo(() => getAnimalIdsInPlan(plan), [plan]);
    const breedingId = useIdFromRoute('breedingId');
    const {t} = useOurTranslation('breeding/detail');
    const dispatch = useDispatch();

    const goToPlanning = useCallback(() => {
        dispatch(routerActions.push('/breedings'));
    }, [dispatch]);

    const loadBreedingAnimals = useCallback((tableDataRequest: Opt<TableDataRequest<PlanningAnimal>>) => {
        dispatch(breedingAction.getAnimalsInBreeding(breedingId, tableDataRequest));
    }, [breedingId, dispatch]);

    const onToggleSelectedAnimals = useCallback((newSelectedIds: Array<number>) => {
        const animalIdsToAdd = difference(newSelectedIds)(animalIdsInPlan);
        const animalIdsToRemove = difference(animalIdsInPlan)(newSelectedIds);

        if (!isEmpty(animalIdsToAdd)) dispatch(planningAction.addAnimalsToPlan(animalIdsToAdd));
        if (!isEmpty(animalIdsToRemove)) dispatch(planningAction.removeAnimalsFromPlan(animalIdsToRemove));
    }, [dispatch, animalIdsInPlan]);

    useEffect(() => {
        dispatch(breedingAction.resetBreedingDetailScreen());
    }, [dispatch]);

    useEffect(() => {
        if (!plan) dispatch(planningAction.getPlan());
    }, [dispatch, plan]);

    useEffect(() => {
        dispatch(breedingAction.getBreeding(breedingId));
    }, [breedingId, dispatch]);

    useEffect(() => {
        dispatch(breedingAction.cleanIdsInLoading());
    }, [breedingId, dispatch]);

    return (
        <Content>
            <PageHeader
                title={getTitle(breeding)}
                onBackTooltip={t('backToPlanning')}
                onBack={goToPlanning}
                smallerTitleOnPhone
                loading={!breeding}
            />

            {breeding && <BreedingDetailActionPanel
                countOfAnimalsInPlan={animalIdsInPlan.length}
                countOfBreedingAnimalsInPlan={getCountOfStableAnimalsInPlan(breedingId, plan)}
                countOfMeasurableAnimalsInBreeding={breeding.numOfMeasurableAnimals}
            />}

            <AnimalsTable
                breedingIsAreal={breeding?.arealRating || false}
                animalsInPlan={animalIdsInPlan}
                getBreedingAnimals={loadBreedingAnimals}
                onToggleSelectedAnimals={onToggleSelectedAnimals}
            />
        </Content>
    );
};

export const BreedingDetail = memo(BreedingDetailBase);
